import React, { useState, useEffect } from 'react';
import "../src/main.scss";
import { ThemeContext, Theme, AppContext, Color, ThemeColors } from './context/AppContext';
import Button from './components/buttons/Buttons';
import Sidebar from './components/sidebar/Sidebar';
import SidebarTenant from './components/sidebar/SidebarTenant';
import Stocklist from './Stocklist';
import ThemeSwitcher from './components/ThemeSwitcher';
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import PlanInfo from './components/houseInfo/components/planInfo/PlanInfo';
import Auth from './pages/auth/index';
import Login from './pages/auth/Login';
import {RoleEnum} from './platform/enums/role';
import TenantHome from './profiles/tenant/TenantHome';
import API from './platform/api/api';
import Signup from './pages/auth/Signup';
import NetworkError from './components/common/NetworkError';

function App() {
  const [sidebarSettings, setSidebarSettings] = useState(false);
  const [switcherVisible, setSwitcherVisible] = useState(true);
  const [theme, setTheme] = React.useState(Theme.Sales);
  const [appData, setAppData] = React.useState(null);
  const [user, setUser] = useState<any>(null);
  const [authToken, setAuthToken] = useState<any>(null);

  const history = useHistory();

  // const [appConfig, setAppConfig] = useState({
  //   theme: {
  //     primary: "#41BCFE"
  //   }
  // });

  const token = localStorage.getItem('authToken');

  // API.interceptors.response.use( API, (error) => {
  //   if(!error.response){
  //     history.push("/networkerror")
  //   }
  //   return Promise.reject(error);
  // });
  // 'Content-Type': 'application/x-www-form-urlencoded'


  const authCheck = () => {
    if (token){
      console.log("CHECKKKKK");
      API.interceptors.request.use(
        async config => {
          config.headers = { 
            'Authorization': `Bearer ${token}`
          }
          return config;
        },
        error => {
          Promise.reject(error)
      });

      API.interceptors.response.use((response) => {
        // if(response.status === 401) {
        // }
        return response;
    }, (error) => {
      if(error.response.status === 401) {
              if(error.response.status == 401){
                  localStorage.removeItem('authToken');
                  localStorage.removeItem('user');
                  setAuthToken(null);
                  setTimeout(() => {
                    history.push('/');
                  }, 1000);
              }
        }
        // if (error.response && error.response.data) {
        //     return Promise.reject(error.response.data);
        // }
        return Promise.reject(error.message);
    });
        return true;
      } else {
        return false;
      }
  }
  // useEffect(() => {
  //       // setUser({
  //       //     user_id: 1,
  //       //     name: "khan",
  //       //     role_id: RoleEnum.Tenant
  //       // });

  //   // If token found return to home
    

  //       setUser({});

  //       const userTheme = Theme[Object.keys(Theme).find((i) => Theme[i as keyof ThemeColors]['role'] == 2) as keyof ThemeColors];
  //       console.log(Theme);
  //       // const userTheme = Theme[Object.keys(Theme).find(2)]
        
  //       setTheme(userTheme)
  // },[]);
  // if( user == false) return <></>;

  useEffect(()=>{
    if(authCheck()){
      const userLocal:any = JSON.parse(localStorage.getItem('user') as any);
      setUser(userLocal);
      // alert("Boom")
      console.log('userLocal');
      console.log(userLocal);
    } else {
      // setUser(false);
      // alert("boom");
    }
  },[])

  const privateRoute = () => {
    // if(authCheck() && user != null){
      
    //   console.log('user');
    //   console.log(JSON.parse(localStorage.getItem('user') as any));
      
      return (
        <Route exact path="/home">
            {authCheck() && user != null && user.role_id != RoleEnum.Tenant && 
              <>
                 <Sidebar/>
               <div className="content">
                <Stocklist />
                </div>
              </>
            }
            {authCheck() && user != null && user.role_id == RoleEnum.Tenant && 
               <TenantHome/>
            }
            {!authCheck() && 
               <Redirect to="/"/>
            }
           
           </Route>
      )
    }

  const loginRoutes = () => {
    // alert("fjfj")
          return (
            !authCheck() ? 
            <>
              <Route exact path="/auth/login">  
                <Login />
              </Route>
              <Route exact path="/">
                <Auth />         
              </Route>
              <Route exact path="/auth/signup">
                <Signup />         
              </Route>
            </> : <Redirect to={'/home'} />
            
           )
  }

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <AppContext.Provider value={{ user, setUser}}>
      <div className="App d-flex">
      
        <Switch>
          
          <Route exact path="/live">
            <PlanInfo />
          </Route>
          <Route exact path="/networkerror">
            <NetworkError />
          </Route>
          {privateRoute()}
          {loginRoutes()}
          
        </Switch>
      
        {/* <button className="btn btn-primary">Hello</button>
        <button className="btn btn-secondary">Hello</button>
        <button className="btn btn-success">Hello</button>
        <button className="btn btn-success rounded-pill">Hello</button>
        <button className="btn btn-primary-lg rounded-pill">Hello Lg</button>
        <button className="btn btn-primary-lg-c rounded-circle">+</button> */}
      </div>
      <ThemeSwitcher onClick={() => setSwitcherVisible(!switcherVisible)} visible={switcherVisible}/>
      
    </AppContext.Provider>
    </ThemeContext.Provider>
  );
}

export default App;
