import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import Button from '../buttons/Buttons';

const Title = styled.h1`
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 105%;
        color: #11142D;
        letter-spacing: -1px;
        display: inline-block;
    `;

    const PageInfo = styled.div`
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.01em;
        width: 274px;
        color: #B2B3BD;
        display: inline-block;
        margin-left: 30px;
    `;

const Header = styled.header`
        border-bottom: 2px solid rgb(199, 199, 199, 0.1);
        padding: 0px 43px;
        width: 100%;
        .title-box{
            padding: 33px 0;
            .action-group{
                float:right;  
                .btn-main{
                    margin-left: 20px;
                }
            }
        }
`;

export default function MainHeader(props:any){

    const [filtersVisible, setFiltersVisible] = useState(false);

    const headerButtons = () => {
       return props.buttons.map((i:any, index:number) => {
            
            return <Button className={index > 0 && index < props.buttons.length ? "btn-main" : ""} onClick={i.onClick} type={i.type} icon={i.icon}>{i.name}</Button>;
        })
    }

    return <>
        <Header className="11">
            <div className="title-box">
                <Title>{props.title}</Title>
                <PageInfo className="d-none d-lg-inline-block">{props.info}</PageInfo>
                <span className="action-group">
                    {headerButtons()}
                </span>
            </div>
        </Header>
    </>

}

{/* <Button onClick={ () => setFiltersVisible(!filtersVisible)} type="circle" icon={<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 1H1L6.6 7.30667V11.6667L9.4 13V7.30667L15 1Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    }></Button>
                    <Button className="btn-main" onClick={ () => alert(true)} icon={<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.0001 1.99999V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M2.00009 9H16.0001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>}>Show Filter</Button>
                    <Button className="btn-main" onClick={ () => alert(true)} icon={<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.0001 1.99999V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M2.00009 9H16.0001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>}>Show Filter</Button> */}