import React, { useEffect, useState } from 'react';
import styled from 'styled-components'

const FileIcon = <svg width="auto" height="auto" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.75 1H3.75C3.02065 1 2.32118 1.28973 1.80546 1.80546C1.28973 2.32118 1 3.02065 1 3.75V25.75C1 26.4793 1.28973 27.1788 1.80546 27.6945C2.32118 28.2103 3.02065 28.5 3.75 28.5H20.25C20.9793 28.5 21.6788 28.2103 22.1945 27.6945C22.7103 27.1788 23 26.4793 23 25.75V9.25L14.75 1Z" stroke="#6C5DD3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.75 1V9.25H23" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.4996 16.1249H6.49963" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.4996 21.625H6.49963" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.24963 10.6249H7.87463H6.49963" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


const MainWrapper = styled.div`
    background: #FAFAFA;
    border-radius: 6px;
    display: flex;
    padding: 21px 29px;
    align-items: baseline;

    display: flex;
    padding: 21px 29px;
    align-items: baseline;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`;

const ContentWrapper = styled.div`

`;

const IconWrapper = styled.div<any>`
    margin-right: 15px;
    width: 7.5%;
`;

type props = {
    name: string,
}

export default function File(props: props) {
    return <>
        <MainWrapper>
            <IconWrapper>
                {FileIcon}
            </IconWrapper>
            <ContentWrapper>
                { props.name }
            </ContentWrapper>
        </MainWrapper>
    </>

}



