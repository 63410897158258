import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import Button from '../buttons/Buttons';

const TH = styled.th`
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #B2B3BD;
    padding-top: 24px;
    padding-bottom: 26px;
    border-bottom: 1px solid #E4E4E4;
`;

const TD = styled.td`
    padding: 24px 0;
    border-bottom: 1px solid #E4E4E4;
`;

const TABLE = styled.table`
    width: 100%;
`;

export default function Table(props: any) {

    const [filtersVisible, setFiltersVisible] = useState(false);

    const columns = () => {
        return props.data.columns.map((i:any) => {
            return <TH scope="col">{ i }</TH>
        })
    }

    const rows = () => {
        return props.data.rows.map((i:any, index:number) => {
           return <tr key={index}> {i.map((k:any) => {
                return <TD>{k}</TD>
            })}
            </tr>
        })
    }

    return <>
        <TABLE className="stable sc-table">
            <thead>
                <tr>
                    {columns()}
                    {/* <th className="selection"><input className="form-check-input" checked type="checkbox" /></th>
                    <th scope="col">Location</th>
                    <th scope="col">Price</th>
                    <th scope="col">Area(m2)</th>
                    <th scope="col">Owner</th>
                    <th className="text-center" scope="col">Action</th> */}
                </tr>
            </thead>
            <tbody>
                { rows() }
                {/* <tr onClick={() => alert(true)}>
                    <td className="selection"><input className="form-check-input" type="checkbox" /></td>
                    <td className="img-location">
                        <img width="70px" src="/940_Pine.png" alt="" /><span>3 Lancaster Street, Strathpine, QL</span>
                    </td>
                    <td>$119,000</td>
                    <td>@900 m2</td>
                    <td>David Bowie</td>
                    <td className="text-center"><Button theme="secondary" type="" className="me-2">Shortlisted</Button></td>
                </tr>
                <tr onClick={() => alert(true)}>
                    <td className="selection"><input className="form-check-input" type="checkbox" /></td>
                    <td className="img-location">
                        <img width="70px" src="/940_Pine.png" alt="" /><span>3 Lancaster Street, Strathpine, QL</span>
                    </td>
                    <td>$119,000</td>
                    <td>@900 m2</td>
                    <td>David Bowie</td>
                    <td className="text-center"><Button type="" className="me-2">Shortlist</Button></td>
                </tr> */}
            </tbody>
        </TABLE>
    </>

}