import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
// import './style.scss'
import { useTheme, Theme } from '../../context/AppContext';
import MainHeader from '../../components/common/MainHeader';
import Button from '../../components/buttons/Buttons';
import Table from '../../components/common/Table';
import MiniCard from '../../components/common/MiniCard';
import HouseInfo from '../tenant/HouseInfo';
import Input from '../../components/common/Input';
import API from '../../platform/api/api';
import Loader from '../../components/common/Loader';

const SearchIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.83105 10.6621C8.49917 10.6621 10.6621 8.49917 10.6621 5.83105C10.6621 3.16294 8.49917 1 5.83105 1C3.16294 1 1 3.16294 1 5.83105C1 8.49917 3.16294 10.6621 5.83105 10.6621Z" stroke="#6C5DD3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.4226 13.2501L10.4204 10.2479" stroke="#6C5DD3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

enum COMPONENTS {
    main,
    houseInfo
}

const Container = styled.div`
    padding: 0px 43px;
`;

const MainWrapper = styled.div`
    flex-wrap: wrap;
    width: 100%;
`;

const FilterWrapper = styled.div`
    padding: 42px 0px;
`;

const FilterLabel = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #808191;
    margin-bottom: 24px;
`;

const FilterIcon =  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 1H1L6.6 7.30667V11.6667L9.4 13V7.30667L15 1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

function HouseList(props:any){

    const [selected, setSelected] = useState("");
    const [status, setStatus] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);
    const [houseInfoVisible, setHouseInfoVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [currentView, setCurrentView] = useState<COMPONENTS>(COMPONENTS.main);
    const { theme, setTheme } = useTheme();

    // Data
    const [houseData, setHouseData] = useState([]);
    const [selectedHouse, setSelectedHouse] :any = useState(null);

    // Filters
    const [locationInput, setLocationInput] = useState("");

    const fetchHouseList = () => {
        setLoading(true);
        API.get('house').then(res => {
            setLoading(false);
            setHouseData(res.data.results);
        }).catch( error => {
            
        })
    }

    const handleFilter = (event:KeyboardEvent) => {
        if(event.key == "Enter"){
            if(locationInput == ""){
                fetchHouseList();
            } else {
                filterHouseList(locationInput);
            }
        }
    }

    const filterHouseList = (query:string) => {
        setLoading(true);
        API.post('house/search', {
            house_plan_id: "2",
            location: query
        }).then(res => {
            setLoading(false);
            setHouseData(res.data.results);
        }).catch( error => {
            
        })
    }

    const handleHouseSelection = (house:any) => {
        setSelectedHouse(house);
        setCurrentView(COMPONENTS.houseInfo)
    }

    // Init Data
    useEffect(() => {
        fetchHouseList();
    },[])

    const headerButtons = () => {
        return [
            {
                name: "Show Filter",
                type: "",
                icon: FilterIcon,
                onClick: () => setFilterVisible(!filterVisible)
            }
        ]
    }

    const genTableData = () => {
        const rows = houseData.map((house:any, index) => [
                    <MiniCard title={house.house_name} desc={house.house_address} image="/static/icons/house1.png" />,
                    <MiniCard title="Platinum NS" desc="4 Bed + OOA" />,
                    "ACARES",
                    house.room_reserved == 0 ? "No Vacancy" : house.room_reserved + " Reserved",
                    <Button type="sm" onClick={() => handleHouseSelection(house)}>View Info</Button>
                ]);
        const columns = ["Location", "Plan", "Service Provider", "Vacancy", "Action"];

        return {columns: columns, rows: rows};
    }

    const tableData = {
        columns: ["Location", "Plan", "Service Provider", "Vacancy", "Action"],
        rows: [
            [
                <MiniCard title="940 Pine Street" desc="Caboolture North, QL" image="/static/icons/house1.png" />,
                <MiniCard title="Platinum NS" desc="4 Bed + OOA" />,
                "ACARES",
                "No Vacancy",
                <Button type="sm" onClick={() => setCurrentView(COMPONENTS.houseInfo)}>View Info</Button>
            ],
            [
                <MiniCard title="11 Sunflower Crescent" desc="Narangba" image="/static/icons/house3.png" />,
                <MiniCard title="Platinum NS" desc="4 Bed + OOA" />,
                "Holistic Home Care",
                "No Vacancy",
                <Button type="sm" onClick={() => setCurrentView(COMPONENTS.houseInfo)}>View Info</Button>
            ],
            [
                <MiniCard title="4 Lancaster Street" desc="Strathpine, QL" image="/static/icons/house2.png" />,
                <MiniCard title="Platinum NS" desc="4 Bed + OOA" />,
                "ACARES",
                "2 Rooms Avail Date: 1 Aug 20",
                <Button type="sm" onClick={() => setCurrentView(COMPONENTS.houseInfo)}>View Info</Button>
            ],
            [
                <MiniCard title="940 Pine Street" desc="Caboolture North, QL" image="/static/icons/house3.png" />,
                <MiniCard title="Platinum NS" desc="4 Bed + OOA" />,
                "Holistic Home Care",
                "No Vacancy",
                <Button type="sm" onClick={() => setCurrentView(COMPONENTS.houseInfo)}>View Info</Button>
            ],

        ]
    }

    const main = () => {
        return  <>  <Loader loading={loading} />
                    <MainHeader 
                        title="House List" 
                        info="See the list of all the houses, info related to it and edit the tenants." 
                        buttons={headerButtons()}
                    />
                    <Container>
                    { filterVisible && 
                            <FilterWrapper>
                            <div className="container">
                                <div className="row">
                                    {/* <div className="col-md-3">
                                        <FilterLabel>Sort by Availability</FilterLabel>
                                        <Input icon={SearchIcon} placeholder="Available" />
                                    </div>
                                    <div className="col-md-3">
                                        <FilterLabel>Sort by Stage of Build</FilterLabel>
                                        <Input icon={SearchIcon} placeholder="Fully Finished" />
                                    </div>
                                    <div className="col-md-3">
                                        <FilterLabel>Sort by Service Provider</FilterLabel>
                                        <Input icon={SearchIcon} placeholder="Name" />
                                    </div> */}
                                    <div className="col-md-3">
                                        <FilterLabel>Search by Location</FilterLabel>
                                        <Input 
                                            value={locationInput} 
                                            onChange={(e:ChangeEvent<HTMLInputElement>) => setLocationInput(e.target.value)} 
                                            icon={SearchIcon} placeholder="Location..."
                                            onKeyPress={handleFilter}
                                             />
                                    </div>
                                </div>
                            </div>
                        </FilterWrapper>
                        }
                        <Table data={genTableData()} />
                    </Container>
                 </>
    }

    const showContent = () => {
        switch (currentView) {
            case COMPONENTS.main:
                return main();
                break;
            case COMPONENTS.houseInfo:
                return <HouseInfo data={selectedHouse} onClose={() => setCurrentView(COMPONENTS.main)} />;
            default:
                break;
        }
    }

    return <>
        <MainWrapper>
            {showContent()}
        </MainWrapper>
    </>
}

export default HouseList;