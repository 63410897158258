import React, { useEffect, useState } from 'react';
import styled from 'styled-components'

type props = {
    title: string,
    desc: string,
    image?: string,
    bg: string,
    onClick?: any
}

const Card = styled.div`
        background: rgba(111, 207, 151, 0.1);
        backdrop-filter: blur(49.6134px);
        border-radius: 6px 0px 0px 6px;
        display: flex;
        align-items: center;
        padding: 15px 0;
        cursor: pointer;
    `;

const MiniCardImage = styled.img`
    border-radius: 50%;
    margin-right: 19px;
    vertical-align: middle;
    max-width: 52px;
    min-width: 52px;
    margin-left: 29px;
`;

const MiniCardTitle = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 147%;
    color: #11142D;
`;

const MiniCardInfoWrapper = styled.div`
    
`;

const MiniCardDesc = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #B2B3BD;
`;

const RoomInfoWrapper = styled.div<any>`
    background: ${props => props.bg};
    backdrop-filter: blur(49.6134px);
    border-radius: 6px 35px 35px 6px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 60px;
    writing-mode: vertical-rl;
    color: white;
    font-weight: 600;
    font-size: 8px;
    z-index: -1;
`;

const RoomNumber = styled.span`
    position: absolute;
    display: block;
    left: 5px;
    top: 32%;
`;

export default function TenantInfoMiniCard(props: props) {
    return <>
        <Card onClick={props.onClick}>
            <RoomInfoWrapper bg={props.bg}><RoomNumber>Room 1</RoomNumber></RoomInfoWrapper>
            {props.image ? <MiniCardImage src={props.image} /> : false}
            <MiniCardInfoWrapper>
                <MiniCardTitle>{ props.title }</MiniCardTitle>
                <MiniCardDesc>{ props.desc }</MiniCardDesc>
            </MiniCardInfoWrapper>
        </Card>
    </>

}