import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import SlideSheet from '../../components/slideSheet/SlideSheet';
import Input from '../common/Input';
import API from '../../platform/api/api';
import Loader from '../common/Loader';

const CheckIcon = <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 1L4.75 9L1 5.36364" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


type props = {
    onClose: Function
}

const Card = styled.div`
        display: flex;
        width: fit-content;
        align-items: center;
    `;

const MiniCardImage = styled.img`
    border-radius: 6px;
    margin-right: 19px;
    vertical-align: middle;
    max-width: 70px;
`;

const MiniCardTitle = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 132.2%;
    color: #11142D;
`;

const MiniCardInfoWrapper = styled.div`
    
`;

const MiniCardDesc = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    margin-top: 3px;
    color: #848484;
`;

const SlideSheetStyled = styled(SlideSheet)`
    position: relative;
`;

export default function AddLand(props: props) {

    // Form Data 
    const [owner, setOwner] :any = useState("");
    const [email, setEmail] :any = useState("");
    const [address, setAddress] :any = useState("");
    const [price, setPrice] :any = useState("");
    const [area, setArea] :any = useState("");
    const [features, setFeatures] :any = useState("");

    const [landCreated, setLandCreated] :any = useState(false);

    // loading
    const [loading, setLoading] :any = useState(false);

    const formReset = () => {
        
    }

    const getData = () => {
        return {
            owner_name: owner,
            owner_email: email,
            land_address: address,
            land_area: area,
            land_features: features,
            price: price,
        }
    }

    const createLand = () => {

        setLoading(true);
        API.post('land/create', getData(), {headers: {'Content-Type': 'application/json'}}).then(res => {
        setLandCreated(true);
        setLoading(false);
    }).catch( error => {
        console.log(error)
        if(error.response.status==401){
            localStorage.removeItem('authToken');
            localStorage.removeItem('user');
            // history.push('/');
          }
    })
    }
    

    return <>
        <SlideSheetStyled bordered={false} title="Add a Land" maxWidth="436px" onClose={props.onClose} action={{buttonType: "circle", icon: CheckIcon, callback: createLand}}>
            <Loader loading={loading} />
            { !landCreated && <>
                <Input value={owner} onChange={(e:any) => setOwner(e.target.value)} className="mb-3" placeholder="Owner’s Name" label="Owner’s Name" />
                <Input value={email} onChange={(e:any) => setEmail(e.target.value)} className="mb-3" placeholder="Owner’s Email" label="Owner’s Email" />
                <Input value={address} onChange={(e:any) => setAddress(e.target.value)} className="mb-3" placeholder="Land Address" label="Land Address" />
                <Input value={price} onChange={(e:any) => setPrice(e.target.value)} className="mb-3" placeholder="Price" label="Price" />
                <Input value={area} onChange={(e:any) => setArea(e.target.value)} className="mb-3" placeholder="Land Area" label="Land Area" />
                <Input value={features} onChange={(e:any) => setFeatures(e.target.value)} className="mb-3" placeholder="Land Features" label="Land Features" />
            </>}
            { landCreated && 
                <p>Land Created!</p>
            }
        </SlideSheetStyled>
    </>

}