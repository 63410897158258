import React, { useState } from 'react';
import styled from 'styled-components';
import Welcome from './Welcome';
import { useHistory } from "react-router-dom";

function Auth(props:any){

    const Container = styled.div`
        display: block;
        width: 100%;
    `;

    

    const Title = styled.div`
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #B2B3BD;
        margin-bottom: 17px
    `;

    const Value = styled.div`
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 132%;
        color: #11142D;
    `;

    return <>
        <Container>
            <Welcome/>
        </Container>
    </>
}

export default Auth;