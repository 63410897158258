import React, { useState } from 'react';
import styled from 'styled-components';
import './style.scss'
import { useTheme, Theme } from '../../context/AppContext';

const Container = styled.div<any>`
    background: rgba(${props => props.theme.rgb}, 0.1);
    border-radius: 47px;
    width: 363px;
    padding: 15px 8px;
    text-align: center;
    position: relative;
`;

const SelectionBackground = styled.span<any>`
    border-radius: 30px;
    padding: 25px 92px;
    position: absolute;
    background-color: ${props => props.theme.hex};
    top: 2px;
    z-index: -1;
    transition: right 1000ms;
    animation-delay: 1s;
    ${ props => props.status ? "right: 2px;" : "right:48.4%"};
`;

const Text = styled.span`
    border-radius: 30px;
    padding: 0px 39px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    display: inline;
    cursor: pointer;
`;

const OptionOne = styled(Text)<any>`
    color: ${props => props.status ? props.theme.hex : '#fff'};
`;

const OptionTwo = styled(Text)<any>`
    color: ${props => props.status ? '#fff' : props.theme.hex};
`;

function ToggleButton(props:any){

    const [selected, setSelected] = useState("");
    const [status, setStatus] = useState(props.status);
    const { theme, setTheme } = useTheme();

    

    // const ButtonSecondary = styled(Button)`
    //     background: rgba(108, 93, 211, 0.08);
    // `;

    return <>
        <Container theme={theme}>
            <SelectionBackground status={status} theme={theme} className={status ? "" : "toggle-switch"}/>
            <OptionOne theme={theme} status={status} onClick={() => {setStatus(false); props.optionOne.callback && props.optionOne.callback()}}>{props.optionOne.text}</OptionOne>
            <OptionTwo theme={theme} status={status} onClick={() => {setStatus(true);props.optionTwo.callback && props.optionTwo.callback()}}>{props.optionTwo.text}</OptionTwo>
        </Container>
    </>
}

export default ToggleButton;