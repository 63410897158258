import React, { useState } from 'react';
import Button from '../../../components/buttons/Buttons';
import styled from 'styled-components';

const DetailTitle = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #B2B3BD;
    max-width: 277px;
    margin: 29px 0;
    display: inline-block;
    `;

const Wrapper = styled.div`
    max-width: 438px;
    background-color: #FDFDFD;
`;

const Container = styled.div`
    margin-top: 38px;
    margin-left: 29px
`;

const CardImage = styled.img`
    max-width: 438px;
    width: 100%;
    position:relative;
    z-index: -1;
`;

const ShadowContainer = styled.div`
    -webkit-box-shadow: inset 0px -70px 200px 0px #000000;
    -moz-box-shadow: inset 0px -70px 200px 0px #000000;
    box-shadow: inset 0px -70px 200px 0px #000000;
    display:inline-block;
    position: relative;
`;

const Title = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #11142D;
    max-width: 277px;
`;

const Details = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #11142D;
`;

const ImageButton = styled(Button)`
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 39px;
`;

const DetailTitleLine = styled.hr`
    background: #E4E4E4;
    display: inline-block;
    max-width: 295px;
    vertical-align: middle;
    margin-left: 12px;
    width: 70%;
`;

const Header = styled.header`
    position: relative;
    z-index: 1;
`;

function InfoCard(props:any){

    

    return <>
        <Wrapper className="info-card-wrapper">
            <Header>
                <ShadowContainer>
                    <CardImage src={props.image} />
                    <ImageButton onClick={() => props.imageButtonClick()} borderColor="#fff" type="outlined">{props.imgButtonText}</ImageButton>
                </ShadowContainer>
            </Header>
            <Container>
                <Title>{props.title}</Title>
                <DetailTitle>{props.detailTitle}</DetailTitle>
                <DetailTitleLine/>
                <Details>{props.details}</Details>
            </Container>
        </Wrapper>
    </>
}

export default InfoCard;
export {DetailTitle};