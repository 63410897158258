import React, { useState } from 'react';
import styled from 'styled-components';
// import './style.scss'
import { useTheme, Theme } from '../../context/AppContext';
import Button from '../buttons/Buttons';
import TodoInput from '../todo/TodoInput';


const Container = styled.div`
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom: 48px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Image = styled.img`
    width: 53px;
`;

const InfoWrapper = styled.div`
    padding: 0 20px;
    width: 100%;
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 147%;
    color: #11142D;
`;

const Description = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #B2B3BD;
`;

const NotesWrapper = styled.div`
    margin-top: 24px;
`;

const NoteWrapper = styled.div`
    background: #F8F8F8;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 23px;
    margin-bottom: 10px;
`;

const NoteText = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #11142D;
    margin-bottom: 14px;
`;

const NoteDate = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #11142D;
    opacity: 0.6;
`;

const TextArea = styled.textarea<any>`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #11142D;
    resize: none;
    outline: none;
    width: 100%;
    border: none;
    margin-bottom: 4px;
    border: 1px solid ${props => props.theme};
    border-radius: 6px;
    padding: 22px 24px;
`;

const NewNoteWrapper = styled.div`
    margin-top: 24px;
`;

const DeleteText = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #11142D;
    cursor: pointer;
`;

const FooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const TodoAddIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.0001 1.99999V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.00009 9H16.0001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

function Note(props: any) {

    const [selected, setSelected] = useState("");
    const [status, setStatus] = useState(false);
    const [notes, setNotes] = useState([]);
    const [newNoteText, setNewNoteText] = useState("");
    const [newNoteVisible, setNewNoteVisible] = useState(false);
    const { theme, setTheme } = useTheme();

    const notesDB = [
        { id: 1, text: "Desley will be gone for a month, for her annual holiday.", date: "15 Aug 2020 • 19:40PM" }
    ]

    const handleDiscard = () => {
        setNewNoteVisible(false);
        setNewNoteText("");
    }

    const handleEnterPress = (e:KeyboardEvent) => {
        if(e.key == "Enter"){
            props.onEnterPress();
            handleDiscard();
            // e.currentTarget.value;
        }
    }

    return <>
        <Container>
            <Header>
                <Image src={props.image} />
                <InfoWrapper>
                    <Title>{props.title}</Title>
                    { props.desc && 
                        <Description>{props.desc}</Description>
                    }
                </InfoWrapper>
                { props.add && 
                    <Button onClick={() => setNewNoteVisible(!newNoteVisible)} type="circle" icon={TodoAddIcon}></Button>
                }
            </Header>
            {newNoteVisible &&
                <NewNoteWrapper>
                    <TodoInput onKeyPress={handleEnterPress} onChange={props.onChange} onDiscard={() => handleDiscard()} value={props.value} placeholder="Write your note on this." />
                </NewNoteWrapper>
            }

            { props.text && props.date && 
                <NotesWrapper>
                        <NoteWrapper>
                            <NoteText>{props.text}</NoteText>
                            <FooterWrapper>
                                <NoteDate>{props.date}</NoteDate>
                                { props.onDelete && 
                                    <DeleteText onClick={props.onDelete}>Delete</DeleteText>
                                }
                            </FooterWrapper>
                        </NoteWrapper>
                    {/* {props.note && props.note.notes.map((i: any) =>
                        <NoteWrapper key={i.id}>
                            <NoteText>{i.text}</NoteText>
                            <NoteDate>{i.date}</NoteDate>
                        </NoteWrapper>
                    )} */}
                </NotesWrapper>
            }
        </Container>
    </>
}

export default Note;