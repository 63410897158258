import React, { useEffect, useState } from 'react';
import styled from 'styled-components'

type props = {

}

export default function NetworkError(props: props) {
    return <>
        <h1>Network Error</h1>
    </>

}