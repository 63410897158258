import React, { useState } from 'react';
import SidebarSetting from '../SidebarSetting';
import styled from 'styled-components';
import Button from '../buttons/Buttons';
import { useTheme, Theme, useAppContext } from '../../context/AppContext';
import './style.scss';
import {CSSTransition } from 'react-transition-group';

const CollapseButton = styled(Button)<any>`
    position: relative;
    display: block;
    top: 132px;
    left: 85%;
    margin-top: -32px;
    z-index: 100;
    border: 1px solid ${props => props.theme.hex};
    background-color: #fff;
`;

export default function Sidebar(props: any) {

    const [sidebarSettings, setSidebarSettings] = useState(false);
    const { theme, setTheme } = useTheme();
    const { user, setUser } = useAppContext();
    const [sidebarVisible, setSidebarVisible] = React.useState(true);

    const activeNavStyle = {backgroundColor: `rgba(${theme.rgb},0.08)`, boxShadow: `inset 9px 0px 0px -3px ${theme.hex}`}

    console.log(user);

    return <>
    <CSSTransition in={sidebarVisible} timeout={300} classNames="sidebar">
    <div className="wrapper">
        <div className="fixed-wrapper">
            <div className="fixed">
                <div id="sidebar">
                    <CollapseButton theme={theme} onClick={() => setSidebarVisible(!sidebarVisible)} type="circle" size="small" icon={
                    <svg width="8px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill={theme.hex}
                        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                        ></path>
                </svg>} />
                    <div className="sidebar-heading">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24" cy="24" r="24" fill="#0049C6" />
                            <path d="M36.9036 26.7909V29.1199L33.2615 32.5235L24.0298 23.3741V16.7201L30.4672 13.9639V20.4119L36.9036 26.7909Z" fill="#B3E5FC" />
                            <path d="M33.2614 32.5235L28.451 36.9866L17.58 26.2125V13.9639L24.0297 16.7201V23.3741L33.2614 32.5235Z" fill="#4FC3F7" />
                            <path d="M28.4472 36.9864L25.0775 40.1323C24.4861 40.6839 23.5637 40.6839 22.9723 40.1323L11.1429 29.1197V13.5223C11.1436 13.0102 11.4041 12.5327 11.8361 12.2517C12.2681 11.9707 12.8137 11.9238 13.2881 12.127L17.5803 13.9637V26.2123L28.4472 36.9864Z" fill="#2196F3" />
                            <path d="M36.9037 13.5224V26.7909L30.4673 20.4119V13.9639L34.7585 12.1271C35.2329 11.9239 35.7785 11.9708 36.2105 12.2518C36.6425 12.5328 36.903 13.0103 36.9037 13.5224Z" fill="#2196F3" />
                        </svg>
                        <div className="user-info d-inline-block">
                            <div className="user">{user.name.toUpperCase()}</div>
                            <div className="role text-muted">Sales</div>
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => setSidebarSettings(!sidebarSettings)} className="sidebar-toggle">
                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g rotate="rotate(180deg)" filter="url(#filter0_d)">
                                    <circle r="15" transform="matrix(-1 0 0 1 27.1428 21)" fill={theme.hex} />
                                </g>
                                <path d="M30.6895 20.2661L27.2653 23.6904L23.841 20.2661" stroke="white" strokeWidth="1.71212" strokeLinecap="round" strokeLinejoin="round" />
                                <defs>
                                    <filter id="filter0_d" x="0.727273" y="0.576875" width="52.8312" height="52.8312" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                                        <feOffset dy="5.99242" />
                                        <feGaussianBlur stdDeviation="5.13636" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                                    </filter>
                                </defs>
                            </svg>

                        </div>
                        
                    </div>
                    {sidebarSettings ? <SidebarSetting /> : null}
                    <div className="list-group list-group-flush">
                        <a href="#" className="list-group-item list-group-item-action">
                            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.2174 2.02808C17.57 2.02808 17.0452 2.55289 17.0452 3.20027C17.0452 3.84766 17.57 4.37247 18.2174 4.37247C18.8647 4.37247 19.3896 3.84766 19.3896 3.20027C19.3896 2.55289 18.8647 2.02808 18.2174 2.02808ZM15.5452 3.20027C15.5452 1.72446 16.7415 0.528076 18.2174 0.528076C19.6932 0.528076 20.8896 1.72446 20.8896 3.20027C20.8896 4.67609 19.6932 5.87247 18.2174 5.87247C16.7415 5.87247 15.5452 4.67609 15.5452 3.20027ZM15.2625 8.20011C15.59 8.4538 15.6497 8.9249 15.396 9.25233L12.467 13.0326C12.3446 13.1906 12.1643 13.2931 11.966 13.3176C11.7678 13.3421 11.5679 13.2865 11.4108 13.1631L8.59238 10.9491L6.06121 14.2389C5.80863 14.5672 5.33773 14.6285 5.00945 14.3759C4.68116 14.1234 4.61979 13.6525 4.87238 13.3242L7.86552 9.43402C7.98761 9.27533 8.16808 9.17205 8.36675 9.14718C8.56541 9.1223 8.76578 9.17789 8.92323 9.30157L11.7437 11.5171L14.2103 8.33361C14.464 8.00618 14.9351 7.94641 15.2625 8.20011ZM5.87872 1.37012C4.18683 1.37012 2.7599 1.97449 1.76005 3.05302C0.766156 4.12514 0.25 5.60468 0.25 7.26428V15.3467C0.25 17.0025 0.754815 18.4807 1.74558 19.5525C2.74295 20.6316 4.17154 21.2317 5.87872 21.2317H14.4828C16.1737 21.2317 17.6006 20.6303 18.6009 19.554C19.5953 18.484 20.1116 17.0064 20.1116 15.3467V8.30776C20.1116 7.89355 19.7758 7.55776 19.3616 7.55776C18.9473 7.55776 18.6116 7.89355 18.6116 8.30776V15.3467C18.6116 16.6984 18.1942 17.7883 17.5021 18.5329C16.8159 19.2713 15.8034 19.7317 14.4828 19.7317H5.87872C4.53784 19.7317 3.52707 19.27 2.8471 18.5344C2.16052 17.7916 1.75 16.7023 1.75 15.3467V7.26428C1.75 5.91244 2.16748 4.8199 2.86008 4.0728C3.54673 3.33211 4.55916 2.87012 5.87872 2.87012H13.1465C13.5607 2.87012 13.8965 2.53433 13.8965 2.12012C13.8965 1.7059 13.5607 1.37012 13.1465 1.37012H5.87872Z" fill={theme.hex} />
                            </svg>
                            <span className="nav-name">Overview</span>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action">
                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.19439 5.44444C6.19439 3.40406 7.84845 1.75 9.88883 1.75C11.9292 1.75 13.5833 3.40406 13.5833 5.44444C13.5833 7.48483 11.9292 9.13889 9.88883 9.13889C7.84845 9.13889 6.19439 7.48483 6.19439 5.44444ZM9.88883 0.25C7.02002 0.25 4.69439 2.57563 4.69439 5.44444C4.69439 8.31326 7.02002 10.6389 9.88883 10.6389C12.7576 10.6389 15.0833 8.31326 15.0833 5.44444C15.0833 2.57563 12.7576 0.25 9.88883 0.25ZM5.44444 13.5834C4.06679 13.5834 2.74556 14.1307 1.77142 15.1048C0.79727 16.079 0.25 17.4002 0.25 18.7779V21.0001C0.25 21.4143 0.585786 21.7501 1 21.7501C1.41421 21.7501 1.75 21.4143 1.75 21.0001V18.7779C1.75 17.798 2.13923 16.8583 2.83208 16.1655C3.52492 15.4727 4.46462 15.0834 5.44444 15.0834H14.3333C15.3132 15.0834 16.2529 15.4727 16.9457 16.1655C17.6385 16.8583 18.0278 17.798 18.0278 18.7779V21.0001C18.0278 21.4143 18.3636 21.7501 18.7778 21.7501C19.192 21.7501 19.5278 21.4143 19.5278 21.0001V18.7779C19.5278 17.4002 18.9805 16.079 18.0064 15.1048C17.0322 14.1307 15.711 13.5834 14.3333 13.5834H5.44444Z" fill={theme.hex} />
                            </svg>

                            <span className="nav-name">Leads</span>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action" style={activeNavStyle}>
                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.4605 0.407986C10.1896 0.197338 9.81038 0.197338 9.53954 0.407986L0.539545 7.40799C0.356855 7.55008 0.25 7.76856 0.25 8V19C0.25 19.7293 0.539731 20.4288 1.05546 20.9445C1.57118 21.4603 2.27065 21.75 3 21.75H7H13H17C17.7293 21.75 18.4288 21.4603 18.9445 20.9445C19.4603 20.4288 19.75 19.7293 19.75 19V8C19.75 7.76856 19.6431 7.55008 19.4605 7.40799L10.4605 0.407986ZM13.75 20.25H17C17.3315 20.25 17.6495 20.1183 17.8839 19.8839C18.1183 19.6495 18.25 19.3315 18.25 19V8.36681L10 1.95015L1.75 8.36681V19C1.75 19.3315 1.8817 19.6495 2.11612 19.8839C2.35054 20.1183 2.66848 20.25 3 20.25H6.25V11C6.25 10.5858 6.58579 10.25 7 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11V20.25ZM7.75 20.25V11.75H12.25V20.25H7.75Z" fill={theme.hex} />
                            </svg>
                            <span className="nav-name">Stock List</span>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.31429 0.25C4.49524 0.25 2.96018 0.898836 1.88354 2.05377C0.812353 3.20285 0.25 4.79292 0.25 6.58516V15.4148C0.25 17.2062 0.809425 18.7964 1.87964 19.9459C2.9555 21.1015 4.49096 21.75 6.31429 21.75H15.6857C17.509 21.75 19.0445 21.1015 20.1204 19.9459C21.1906 18.7964 21.75 17.2062 21.75 15.4148V6.58516C21.75 4.79384 21.1906 3.20365 20.1204 2.05412C19.0445 0.898535 17.509 0.25 15.6857 0.25H6.31429ZM1.75 6.58516C1.75 5.10432 2.21146 3.90181 2.98074 3.07659C3.74458 2.25721 4.86666 1.75 6.31429 1.75H15.6857C17.1386 1.75 18.2603 2.25751 19.0225 3.07623C19.7904 3.90101 20.25 5.1034 20.25 6.58516V15.4148C20.25 16.8966 19.7904 18.099 19.0225 18.9238C18.2603 19.7425 17.1386 20.25 15.6857 20.25H6.31429C4.86142 20.25 3.73974 19.7425 2.9775 18.9238C2.20962 18.099 1.75 16.8966 1.75 15.4148V6.58516ZM11.7881 5.91912C11.7881 5.50491 11.4523 5.16912 11.0381 5.16912C10.6239 5.16912 10.2881 5.50491 10.2881 5.91912V16.0618C10.2881 16.4761 10.6239 16.8118 11.0381 16.8118C11.4523 16.8118 11.7881 16.4761 11.7881 16.0618V5.91912ZM7.12143 9.20171C7.12143 8.7875 6.78564 8.45171 6.37143 8.45171C5.95721 8.45171 5.62143 8.7875 5.62143 9.20171V16.0618C5.62143 16.4761 5.95721 16.8118 6.37143 16.8118C6.78564 16.8118 7.12143 16.4761 7.12143 16.0618V9.20171ZM15.6286 12.0768C16.0428 12.0768 16.3786 12.4126 16.3786 12.8268V16.0618C16.3786 16.4761 16.0428 16.8118 15.6286 16.8118C15.2144 16.8118 14.8786 16.4761 14.8786 16.0618V12.8268C14.8786 12.4126 15.2144 12.0768 15.6286 12.0768Z" fill={theme.hex} />
                            </svg>
                            <span className="nav-name">Performance</span>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action">
                            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3 1.75C2.66848 1.75 2.35054 1.8817 2.11612 2.11612C1.8817 2.35054 1.75 2.66848 1.75 3V17C1.75 17.3315 1.8817 17.6495 2.11612 17.8839C2.35054 18.1183 2.66848 18.25 3 18.25H19C19.3315 18.25 19.6495 18.1183 19.8839 17.8839C20.1183 17.6495 20.25 17.3315 20.25 17V6C20.25 5.66848 20.1183 5.35054 19.8839 5.11612C19.6495 4.8817 19.3315 4.75 19 4.75H10C9.74924 4.75 9.51506 4.62467 9.37596 4.41603L7.59861 1.75H3ZM1.05546 1.05546C1.57118 0.539731 2.27065 0.25 3 0.25H8C8.25076 0.25 8.48494 0.375326 8.62404 0.583975L10.4014 3.25H19C19.7293 3.25 20.4288 3.53973 20.9445 4.05546C21.4603 4.57118 21.75 5.27065 21.75 6V17C21.75 17.7293 21.4603 18.4288 20.9445 18.9445C20.4288 19.4603 19.7293 19.75 19 19.75H3C2.27065 19.75 1.57118 19.4603 1.05546 18.9445C0.539731 18.4288 0.25 17.7293 0.25 17V3C0.25 2.27065 0.539731 1.57118 1.05546 1.05546Z" fill={theme.hex} />
                            </svg>

                            <span className="nav-name">File Managment</span>
                        </a>
                    </div>
                    <div className="nav-footer">
                        <img src="logo.svg" alt="logo" />
                        <span>By Property <br />Direct</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
        </CSSTransition>
    </>
}
