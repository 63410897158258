import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import styled from 'styled-components';
import MainHeader from '../../components/common/MainHeader';
import Loader from '../../components/common/Loader';
import Table from '../../components/common/Table';
import Input from '../../components/common/Input';
import SlideSheet from '../../components/slideSheet/SlideSheet';
import API from '../../platform/api/api';
import Button from '../../components/buttons/Buttons';

const FilterIcon =  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 1H1L6.6 7.30667V11.6667L9.4 13V7.30667L15 1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

const AddIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.0001 1.99999V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.00009 9H16.0001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const EditIcon = <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.77853 3.13092H2.60375C2.21163 3.13092 1.83556 3.28669 1.55829 3.56397C1.28102 3.84124 1.12524 4.2173 1.12524 4.60943V14.959C1.12524 15.3511 1.28102 15.7272 1.55829 16.0045C1.83556 16.2817 2.21163 16.4375 2.60375 16.4375H12.9533C13.3454 16.4375 13.7215 16.2817 13.9988 16.0045C14.276 15.7272 14.4318 15.3511 14.4318 14.959V9.78421" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.323 2.02206C13.6171 1.72796 14.0159 1.56274 14.4318 1.56274C14.8478 1.56274 15.2466 1.72796 15.5407 2.02206C15.8348 2.31615 16 2.71503 16 3.13094C16 3.54685 15.8348 3.94573 15.5407 4.23982L8.51781 11.2627L5.56079 12.002L6.30005 9.04497L13.323 2.02206Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const Container = styled.div`
    padding: 0px 43px;
`;

type props = {
}

export default function TenantManager(props: props) {
    const [loading, setLoading] = useState(false);
    const { user } = useAppContext();
    
    // Data
    const [tenantData, setTenantData] = useState([]);
    const [selectedTenant, setSelectedTenant]: any = useState(null);
    const [formTitle, setFormTitle] = useState("Add Tanent");
    const [slidesheetVisible, setSlidesheetVisible] = useState(false);
    const [slideSheetLoading, setSlideSheetLoading] = useState(false);

    // Form Data
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [DOB, setDOB] = useState("");
    const [guardian, setGuardian] = useState("");
    const [guardianContact, setGuardianContact] = useState("");
    const [financialDelegation, setFinancialDelegation] = useState("");
    const [supportCoordinator, setSupportCoordinator] = useState("");
    const [sdaId, setSdaId] = useState("");
    const [area, setArea] = useState("");
    const [createdBy, setCreatedBy] = useState("");


    const headerButtons = () => {
        return [
            {
                name: "Add a Tenant",
                type: "",
                icon: AddIcon,
                onClick: () => alert("fsd")
            }
        ]
    }

    const genTableData = () => {
        const rows = tenantData.map((tenant: any, index) => [
            tenant.name,
            tenant.email,
            tenant.guardian_name,
            tenant.sda_id,
            <Button type="sm" onClick={() => handleWaiteeSelection(tenant)}>View Info</Button>
        ]);
        const columns = ["Name", "E-Mail", "Guardian", "sda_id", "Action"];


        return { columns: columns, rows: rows };
    }

    const fetchTenantData = () => {
        setLoading(true);
        API.get('tenants').then(res => {
            setLoading(false);
            setTenantData(res.data.results);
        }).catch(error => {

        })
    }

    const getDBFormatData = () => {
        return {
            name: name,
            email: email,
            dob: DOB,
            guardian_name: guardian,
            guardian_contact: guardianContact,
            financial_delegation: financialDelegation,
            sda_id: sdaId,
            area: area,
            created_by: user.employee_id,
        }
    }

    const createTenant = () => {

        setSlideSheetLoading(true);

        API.post(
            'tenant/create',
            getDBFormatData(),
            { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                fetchTenantData();
                setSlidesheetVisible(false);
                setSlideSheetLoading(false);
            }).catch(error => {

            })
    }

    const updateTenant = () => {

        setSlideSheetLoading(true);

        API.patch(
            'tenant/update/' + selectedTenant.tenant_id,
            getDBFormatData(),
            { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                fetchTenantData();
                setSlidesheetVisible(false);
                setSlideSheetLoading(false);
            }).catch(error => {

            })
    }

    const handleFormSubmitAction = () => {
        if (!selectedTenant) {
            createTenant();
        } else {
            updateTenant();
        }
    }

    const handleWaiteeSelection = (tenant: any) => {
            setFormTitle("Update Tanent");
            setName(tenant.name);
            setEmail(tenant.email);
            setDOB(tenant.dob);
            setGuardian(tenant.guardian_name);
            setGuardianContact(tenant.guardian_contact);
            setFinancialDelegation(tenant.financial_delegation);
            setSupportCoordinator(tenant.support_coordinator_id);
            setSdaId(tenant.sda_id);
            setArea(tenant.area);
            setCreatedBy(tenant.created_by);

        setSlidesheetVisible(true);
        // setCurrentView(COMPONENTS.houseInfo)
    }

    // Init Data
    useEffect(() => {
        fetchTenantData();
    }, [])

    return <>  
        <Loader loading={loading} />
        <MainHeader 
            title="Tenant List" 
            info="See the list of all the tenants, info related to it and edit the tenants." 
            buttons={headerButtons()}
        />

        <Container>
            <Table data={genTableData()} />
            { slidesheetVisible &&
                <SlideSheet loading={slideSheetLoading} title={formTitle} maxWidth="437px" onClose={() => setSlidesheetVisible(false)} action={{ icon: EditIcon, buttonType: "circle", callback: () => handleFormSubmitAction() }} bordered={false}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-4 mt-2">
                                <Input value={name} label="Full Name" placeholder="Full Name" onChange={(e: any) => setName(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={email} label="Email Address" placeholder="Email Address" onChange={(e: any) => setEmail(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={DOB} label="Date of Birth" placeholder="Date of Birth" onChange={(e: any) => setDOB(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={guardian} label="Guardian Name" placeholder="Guardian Name" onChange={(e: any) => setGuardian(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={guardianContact} label="Guardian Contact" placeholder="Guardian Contact" onChange={(e: any) => setGuardianContact(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={financialDelegation} label="Financial Delegation" placeholder="Financial Delegation" onChange={(e: any) => setFinancialDelegation(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={supportCoordinator} label="Support Coordinator" placeholder="Support Coordinator" onChange={(e: any) => setFinancialDelegation(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={sdaId} label="SDA ID" placeholder="SDA ID" onChange={(e: any) => setSdaId(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={area} label="Area" placeholder="Area" onChange={(e: any) => setArea(e.currentTarget.value)} />
                            </div>
                        </div>
                    </div>
                </SlideSheet>
            }
        </Container>
 </>

}



