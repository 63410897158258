import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import styled from 'styled-components';
import './signup.scss';
import logo from '../../assets/images/logo.svg';
import { useTheme, Theme, Color, useAppContext, ThemeColors } from '../../context/AppContext';
import {RoleEnum} from '../../platform/enums/role';
import API from '../../platform/api/api';
import ButtonPR from '../../components/buttons/Buttons';

export enum RoleImages {
    Sales = "/static/icons/av-sales-login-blue.svg",
    Build = "/static/icons/av-sales-login-green.svg",
    Tenant = "/static/icons/av-sales-login-purple.svg",
    Investor = "/static/icons/av-sales-login-org.svg",
  }


const BGCircle = styled.div<any>`
    position: fixed;
    width: 1264px;
    height: 1264px;
    z-index: -11;
    background: ${props => props.bg};
    border-radius: 1023.84px;
    left: calc(50% - 1264px/2 - 327px);
    top: calc(50% - 1264px/2);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: url('/static/icons/ralph.svg');
    background-size: 230px;
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 499px;
    background-position-y: 374px;
    background-attachment: fixed;
    /* transition: all ease-in-out 0.1s; */

    &::before{
        content: '';
        width: 300px;
        height: 100%;
        position: absolute;
        top: 407px;
        left: 38%;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(${props=>props.roleImage});
        /* transition: all ease-in-out 0.1s; */

        
    }
    &::after{
            content: '${props => props.imageTitle}';
            position: relative;
            display: block;
            top: 884px;
            left: 0%;
            text-align: center;
            font-weight: 600;
            font-size: 40px;
            line-height: 105%;
            /* identical to box height, or 42px */

            text-align: center;
            letter-spacing: -1px;

            /* White */

            color: #FFFFFF;
            
        }
`;

const UserRole = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 9.65094px;
    line-height: 13px;
    color: #EBEBEB;
`;

const BadgeWrapper = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

const SignupHighlight = styled.span<any>`
    color: ${props=>props.color};
    text-decoration: underline;
    cursor: pointer;
`;

const Text = styled.span<any>`
    color: ${props=>props.color};
    margin: 0 ${props=> props.right ? "19px" : false} 0 ${props=> props.left ? "19px" : false};
`

const ErrorText = styled.div`
    color: #ff4646;
`;

const SuccessText = styled.div<any>`
    color: ${props=>props.color};
`;

const Loader = styled.div<any>`
    border-top-color: ${props=>props.color};
    margin: 0 auto;
`;

const SuccessWrapper = styled.div`
    position: relative;
    padding: 100px 0;
    top: 50%;
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 52.8378px;
    line-height: 105%;
    /* or 55px */
    max-width: 634.05px;
    letter-spacing: -1.32095px;
    margin-bottom: 40px;
    /* White */

    color: #FFFFFF;

    `;

const PageDescription = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 22.4561px;
    line-height: 27px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    color: #FFFFFF;
    max-width: 446.48px;
`;

const Button = styled.button<any>`
    background: ${props=>props.bg};
    border-radius: 12px;
    padding: 19px 33%;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    border: none;
    text-align: center;
    outline: none;
    color: #fff;
`;

const LoginBadgeCircle = styled.div`
        background: #FFFFFF;
        vertical-align: middle;
        box-shadow: 0px 8px 23px rgba(77,76,76,0.06);
        width: 59px;
        height: 59px;
        border-radius: 50%;
        padding: 0px 0px;
        /* margin-left: 20px; */
        margin-right: 20px;
        display: inline-block;
    `;

const LoginBadgeText = styled.span`
    font-size: 22px;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.9);
`;

const LoginBadgeIcon = styled.img`
    width:59px;
`;

const Logo = styled.img`
    margin-left: 66px;
`;

const LogoWrapper = styled.div`
    position: fixed;
    top: 75px;
    left: 81px;     
`;

const Container = styled.div`
        display: block;
        width:100%;
    `;



const LoginBadge = styled.div<any>`
    /* display:inline-block; */
    padding: 18px 13px;
    min-width: 239px;
    width: 239px;
    height: 95pxpx;
    border-radius: 81px;
    background: linear-gradient(180deg, ${ props => props.bg } 0%, ${props => props.bgsec} 100%);
    /* top: -85px; */
    /* position: relative; */
    padding: 18px 22px;
    margin-bottom:42px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 95px;
    position: relative;
    top: -43px;
    left:0;
    cursor: pointer;
    transition: all 0.3s;
    &:hover{
        left: 45px;
        transition: all 0.3s;
    }
`;

function Signup(props:any){

    const { theme, setTheme } = useTheme();
    const { user, setUser } = useAppContext();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState(1);
    const [formError, setFormError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoggedin, setIsLoggedin] = useState(false);
    const { state } : any = useLocation();
    const [countDown, setCountDown] : any = useState(null);
    const [signupSuccess, setSignupSuccess] : any = useState(false);
    let timeoutID = undefined;
    
    const history = useHistory();

    const signup = () => {
        // console.log([email, password, role, name])
        // return false;
        setFormError(false);
        setLoading(true);

        API.post('signup', {email: email, password: password, name: name, role_id: role})
        .then(res => {
            setSignupSuccess(true);
            setFormError(false);
            setLoading(false);
            setCountDown(5);

        }).catch(error => {
            setSignupSuccess(false);
            setFormError(true);
            setLoading(false);
        });
}

    useEffect(() => {
        if(countDown===0){
        setCountDown(null);
        history.push("/");
        }

        if (signupSuccess == false || !countDown) return;

        const intervalId = setInterval(() => {

        setCountDown(countDown - 1);
        }, 1000);

        return () => clearInterval(intervalId);

    }, [countDown]);


    return <>
        <div className="signup-page-wrapper">
            <Container>
                <LogoWrapper className="left-top-box">
                    <span className="button-left">
                        <ButtonPR bg="#fff" onClick={() => history.push("/")} type={"circle"} icon={<svg width="8px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill="#F9BA6A"
                        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                        ></path>
                        </svg>}></ButtonPR>
                        <Text color="#fff" right={true}>Go Back</Text>
                    </span>
                    <Logo src="/static/icons/logo-new.svg" />
                </LogoWrapper>
                
                <div className="top-actions">
                    <span className="button-right">
                        <ButtonPR bg="#F9BA6A" onClick={props.onClose} type={"circle"} size="small" icon={<svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.07247 11.0312C3.57768 11.0312 3.98723 10.6216 3.98723 10.1164C3.98723 9.61121 3.57768 9.20166 3.07247 9.20166C2.56727 9.20166 2.15771 9.61121 2.15771 10.1164C2.15771 10.6216 2.56727 11.0312 3.07247 11.0312Z" fill="#fff"/>
                        <path d="M3.07273 0.96875C1.45866 0.96875 0.145508 2.2819 0.145508 3.89597C0.145508 4.30015 0.473137 4.62777 0.877313 4.62777C1.28149 4.62777 1.60912 4.30015 1.60912 3.89597C1.60912 3.08894 2.26569 2.43236 3.07273 2.43236C3.87976 2.43236 4.53634 3.08894 4.53634 3.89597C4.53634 4.703 3.87976 5.35958 3.07273 5.35958C2.66855 5.35958 2.34092 5.68721 2.34092 6.09138V7.9209C2.34092 8.32507 2.66855 8.6527 3.07273 8.6527C3.4769 8.6527 3.80453 8.32507 3.80453 7.9209V6.73058C5.06565 6.40482 5.99995 5.25746 5.99995 3.89597C5.99995 2.2819 4.6868 0.96875 3.07273 0.96875Z" fill="white"/>
                        </svg>}></ButtonPR>
                    </span>
                </div>

                <BGCircle bg="#F9BA6A"  />
                <div className="row content-wrapper">
                    <div className="col-md-6">
                        <div className="page-info-wrapper">
                                <Title>Ask the admin to approve.</Title>
                                <PageDescription>Once you sign up here, the admin of this software will have to approve the request for you to sign up successfully.</PageDescription>
                                <PageDescription>This is for one time only, after this you can login anytime using your credentials.</PageDescription>
                            </div>
                    </div>
                    <div className="col-md-4">
                        <div className="round-wrapper-login">
                            <div className="">
                                
                                {signupSuccess && !loading && 
                                    <SuccessWrapper className="mb-4">
                                        <SuccessText color="#F9BA6A">Success! Account Created, Redirecting to login - {countDown} </SuccessText>
                                    </SuccessWrapper>
                                }
                            </div>
                            {!signupSuccess && 
                                <div className="container">
                                    <div className="form-group">
                                        <div className="input-wrapper">
                                            <label htmlFor="name">
                                                Name
                                            </label>
                                            <input key={"anem"} value={name} type="text" onChange={(e:any) =>setName(e.target.value)} />
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="email">
                                                Email Address
                                            </label>
                                            <input key={"usr"} value={email} type="email" onChange={(e:any) =>setEmail(e.target.value)} />
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="password">
                                                Password
                                            </label>
                                            <input key={"psw"} value={password} type="password" onChange={(e:any) =>setPassword(e.target.value)} />
                                        </div>
                                        <div className="input-wrapper">
                                            <label htmlFor="Role">
                                                Role/Department
                                            </label>
                                            <select className="form-control" onChange={(e:any)=>setRole(e.target.value)} value={password} name="role" id="role">
                                                <option value="1">Sales</option>
                                                <option value="2">Build</option>
                                                <option value="3">Tenant</option>
                                                <option value="4">Investor</option>
                                            </select>
                                            {/* <input key={"role"} value={password} type="password" onChange={(e:any) =>setPassword(e.target.value)} /> */}
                                        </div>
                                        <Button onClick={signup} bg={'#F9BA6A'} className="">Continue</Button>
                                        {formError && 
                                            <div className="mt-4 mb-4">
                                                <ErrorText>Sorry! Account not created.</ErrorText>
                                            </div>
                                        }
                                        {loading && 
                                            <div className="mt-4 mb-4 text-center">
                                                <Loader color="#F9BA6A" className="min-loader">
                                                    
                                                </Loader>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        
                        </div>
                    </div>
                </div>
                
            </Container>
        </div>
    </>
}

export default Signup;