import React, { useState } from 'react';
import styled from 'styled-components';
// import './style.scss'
import { useTheme, Theme } from '../../context/AppContext';

const Container = styled.div`
    background: #F8F8F8;
    border-radius: 6px;
    padding: 22px 24px;
    display: flex;
`;

const Text = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #11142D;
    resize: none;
    outline: none;
    width: 100%;
    border: none;
    display: inline-block;
    max-width: 262px;
    vertical-align: middle;
`;

const ActionsWrapper = styled.div`
    display:inline-block;
`;

const Checkbox = styled.input<any>`
    margin-right:19px;
    display: inline-block;
    margin-top: 0;
`;

const DeleteText = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #11142D;
    cursor: pointer;
`;

function TodoItem(props:any){

    const [selected, setSelected] = useState("");
    const [status, setStatus] = useState(false);
    const { theme, setTheme } = useTheme();

    const checked = props.checked ? "checked" : "";

    return <>
        <Container>
            <ActionsWrapper>
                <Checkbox onChange={props.onCheck} checked={props.checked} defaultChecked={false} className="form-check-input default-checkbox" type="checkbox" />
            </ActionsWrapper>
            <Text>{ props.text }</Text>
            <DeleteText onClick={props.onDelete}>Delete</DeleteText>
        </Container>
    </>
}

export default TodoItem;