import React, { useEffect, useState } from 'react';
import styled from 'styled-components'

type props = {
    title: string,
    desc: string,
    image?: string
}

const Card = styled.div`
        display: flex;
        width: fit-content;
        align-items: center;
    `;

const MiniCardImage = styled.img`
    border-radius: 6px;
    margin-right: 19px;
    vertical-align: middle;
    max-width: 70px;
`;

const MiniCardTitle = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 132.2%;
    color: #11142D;
`;

const MiniCardInfoWrapper = styled.div`
    
`;

const MiniCardDesc = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    margin-top: 3px;
    color: #848484;
`;

export default function MiniCard(props: props) {
    return <>
        <Card>
            {props.image ? <MiniCardImage src={props.image} /> : false}
            <MiniCardInfoWrapper>
                <MiniCardTitle>{ props.title }</MiniCardTitle>
                <MiniCardDesc>{ props.desc }</MiniCardDesc>
            </MiniCardInfoWrapper>
        </Card>
    </>

}