import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useTheme, Theme } from '../../context/AppContext';

const FileIcon = <svg width="auto" height="auto" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.75 1H3.75C3.02065 1 2.32118 1.28973 1.80546 1.80546C1.28973 2.32118 1 3.02065 1 3.75V25.75C1 26.4793 1.28973 27.1788 1.80546 27.6945C2.32118 28.2103 3.02065 28.5 3.75 28.5H20.25C20.9793 28.5 21.6788 28.2103 22.1945 27.6945C22.7103 27.1788 23 26.4793 23 25.75V9.25L14.75 1Z" stroke="#6C5DD3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.75 1V9.25H23" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.4996 16.1249H6.49963" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.4996 21.625H6.49963" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.24963 10.6249H7.87463H6.49963" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


const MainWrapper = styled.div`
    
`;

const ContentWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const TextInput = styled.input<any>`
    background: rgba(${ props => props.rgb }, 0.08);
    opacity: 0.9;
    border-radius: 6px;
    padding: 15.5px 26px;
    outline: none;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.color};
    width: 100%;
    &::placeholder{
        color: rgba(${ props => props.rgb }, 0.5);
    }
    &:focus{
        outline: 2px solid ${ props => props.color };
        outline-style: auto;
        background-color: #fff;

        &+ .lblfocus{
            display: block;
        }
    }
    
`;

const LabelWrapper = styled.div`
    position: absolute;
    left: 0;
    top: -15px;
    padding: 0 15px;
    display:none;
`;

const IconWrapper = styled.div<any>`
    position: absolute;
    right: 20px;
    top: 16px;
    width: 13px;
    line-height: 0;

    path{
        stroke: ${ props => props.color }
    }
`;

const Label = styled.label`
    position: relative;
    padding: 0 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #B2B3BD;
    /* &::before{
        content: "";
        height: 2px;
        background-color: white;
        width: 100%;
        position: absolute;
        bottom: 8px;
        z-index: -1;
    } */
`;

const LabelBackLine = styled.div`
    height: 2px;
    background-color: #ffffff;
    top: 14px;
    bottom: 8px;
    position: relative;
`;

export default function Input(props:any) {
    const { theme, setTheme } = useTheme();
    const [focused, setFocused] = useState(false);

    return <>
        <MainWrapper>
            <ContentWrapper>
                <TextInput onFocused={() => setFocused(true)} color={theme.hex} rgb={theme.rgb} {...props} />
                { props.label && 
                    <LabelWrapper className="lblfocus">
                        <LabelBackLine/>
                        <Label>{ props.label }</Label>
                    </LabelWrapper>
                }
                <IconWrapper color={theme.hex}>
                {props.icon}
                </IconWrapper>
            </ContentWrapper>
        </MainWrapper>
    </>

}



