import axios from 'axios';
import clAPI from './corelogic';

export default axios.create({
  baseURL: `https://cors-anywhere.herokuapp.com/http://sda.waqasbazai.co`,
  headers: {'Authorization': 'Bearer '+ localStorage.getItem('authToken')}
});

export {clAPI}

