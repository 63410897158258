import React, { ChangeEvent, useEffect, useState } from 'react';
import { ThemeContext, Theme } from './context/AppContext';
import styled from 'styled-components'
import Button from './components/buttons/Buttons';
import ToggleButton from './components/buttons/ToggleButton';
import PlanCard from './components/planCard/PlanCard';
import { useTheme, useAppContext } from './context/AppContext';
import BottomSheet from './components/bottomSheet/BottomSheet';
import SlideSheet from './components/slideSheet/SlideSheet';
import FullSheet from './components/fullSheet/FullSheet';
import {HouseInfo} from './components/houseInfo/HouseInfo';
import LandInfo from './components/houseInfo/LandInfo';
import Breadcrumbs from './components/breadcrumbs/index';
import CreatePackage from './components/createPackage/index';
import ImageViewer from './components/imageViewer/index';
import API, {clAPI} from './platform/api/api';
import Loader from './components/common/Loader';
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import AddLand from './components/common/AddLand';

const getSuggestions = (string:String, params: any) => {
    return clAPI.get('property/au/v2/suggest.json', params)
}

const token = localStorage.getItem('authToken');
  
const authCheck = () => {
  if (token){
      return true;
    } else {
      return false;
    }
}

const Title = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 105%;
    color: #11142D;
    letter-spacing: -1px;
    display: inline-block;
`;

const PageInfo = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    width: 274px;
    color: #B2B3BD;
    display: inline-block;
    margin-left: 30px;
`;

enum TableResultTypes {
    Lands,
    Houses
}

export default function Stocklist(){

    const [selectedPlanCard, setSelectedPlanCard] = useState(1);
    const [slideSheetVisible, setSlideSheetVisible] = useState(false);
    const [fullSheetVisible, setFullSheetVisible] = useState(false);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableResultType, settableResultType] = useState(TableResultTypes.Houses);
    const [createdPackageVisible, setCreatedPackageVisible] = useState(false);

    const [landInfoVisible, setLandInfoVisible] = useState(false);
    const [addLandVisible, setAddLandVisible] = useState(false);
    const history = useHistory();

    // Table Data - Rows
    const [tableResultData, setTableResultData] :any = useState([]);
    // Table Data Columns
    const [tableResultColumns, setTableResultColumns] :any = useState([]);
    // Table Selected Records
    const [tableSelections, setTableSelections] :any = useState([]);
    const [tableSelected, setTableSelected] :any = useState(null);

    // Filter
    const [locationSearchInput, setLocationSearchInput] = useState("");

    const selectEvent = (selection:any) => {
        setTableSelections({filteredTodos: [...tableSelections.filter((item:any) => selection ? selection : true)]})
    }
    // Contexts
    const { theme, setTheme } = useTheme();
    const { user, setUser } = useAppContext();

    const filterHouseList = (query:string) => {
        setLoading(true);
        API.post('house/search', {
            house_plan_id: "2",
            location: query
        }).then(res => {
            setLoading(false);
            setTableResultData(res.data.results);
        }).catch( error => {
            
        })
    }

    const filterLandList = (query:string) => {
        setLoading(true);
        API.post('land/search', {
            "location": query
        }).then(res => {
            setLoading(false);
            setTableResultData(res.data.results);
        }).catch( error => {
            
        })
    }

    const handleLocationFilter = (e: React.KeyboardEvent)=> {
        if(e.key == "Enter"){
            if(tableResultType == TableResultTypes.Houses){
                if(locationSearchInput == ""){
                    fetchHouses();
                } else {
                    filterHouseList(locationSearchInput);
                }
            }

            if(tableResultType == TableResultTypes.Lands){
                if(locationSearchInput == ""){
                    fetchLands();
                } else {
                    filterLandList(locationSearchInput);
                }
            }


        }
    }

    const fetchHouses = () => {
        API.get('house').then(res => {
            setTableResultData(res.data.results);
            setTableResultColumns([
                "",
                "Name",
                "Location",
                "Rooms",
                "Reserved?",
                "Action"
            ]);
            setLoading(false);
        })
    }

    const fetchLands = () => {
        API.get('land').then(res => {
            setTableResultData(res.data.results);
            setTableResultColumns([
                "",
                "Location",
                "Area",
                "Owner",
                "Action"
            ]);
            setLoading(false);
        })
    }

    useEffect(()=>{
        setLoading(true);
        if(tableResultType == TableResultTypes.Houses){
            fetchHouses();
        } else {
            fetchLands()
        }
        
    },[tableResultType])


    

    return <>
        <Loader loading={loading}/>
        <header className="content-header">
            <div className="title-box">
                <Title>Stocklist</Title>
                <PageInfo className="d-none d-lg-inline-block">Take a look at available land and house plans to prepare a package for the lead.</PageInfo>
                <span className="action-group">
                    <Button type="outlined" className="" onClick={ () => setAddLandVisible(true)}>Add a Land</Button>
                    <Button className="me-3 ms-3" onClick={ () => setFiltersVisible(!filtersVisible)} type="circle" icon={<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 1H1L6.6 7.30667V11.6667L9.4 13V7.30667L15 1Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    }></Button>
                    <Button className="" onClick={ () => setSlideSheetVisible(true)} icon={<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.0001 1.99999V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M2.00009 9H16.0001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>}>Create Package</Button>
                </span>
            </div>
        </header>
        <div className="page-content">
            { filtersVisible && 
                <section className="sorting-box">
                <div className="sort-by-plans">
                    <div className="small-title">
                        Sort by Plans
                    </div>
                    <div className="row">
                        <div className="col-md-3-5 mb-4">
                            <PlanCard onClick={() => {setSelectedPlanCard(1); setFullSheetVisible(true)}} selected={selectedPlanCard == 1} title="SDA Platinum 1" description="Area Req: 400 M2" img="/plane.png"></PlanCard>
                        </div>
                        <div className="col-md-3-5 mb-4">
                            <PlanCard onClick={() => {setSelectedPlanCard(2); setFullSheetVisible(true)}} selected={selectedPlanCard == 2}   title="SDA Platinum 2" description="Area Req: 400 M2" img="/plane.png"></PlanCard>
                        </div>
                        <div className="col-md-3-5 mb-4">
                            <PlanCard onClick={() => {setSelectedPlanCard(3); setFullSheetVisible(true)}} selected={selectedPlanCard == 3}   title="SDA Platinum 3" description="Area Req: 400 M2" img="/plane.png"></PlanCard>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="sort-by-land-b-l">
                    <div className="d-flex justify-content-sm-between justify-content-lg-around flex-wrap">
                        <div className="">
                            <div className="box">
                                    <div className="title text-lg">
                                        Sort by Land Size
                                    </div>
                                    <div className="wrapper">
                                        <div className="input-group-i">
                                            <span className="text-lg-sm label">Minimum Land Size (In 1 )</span>
                                            <input type="text"/>
                                        </div>
                                        <div className="input-group-i">
                                            <span className="text-lg-sm label">Minimum Land Size (In 2 )</span>
                                            <input type="text"/>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="">
                        <div className="box">
                                    <div className="title text-lg">
                                        Sort by Land Size
                                    </div>
                                    <div className="wrapper">
                                        <div className="input-group-i">
                                            <span className="text-lg-sm label">Minimum Land Size (In 1 )</span>
                                            <input type="text"/>
                                        </div>
                                        <div className="input-group-i">
                                            <span className="text-lg-sm label">Minimum Land Size (In 2 )</span>
                                            <input type="text"/>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="outer-wrapper">
                        <div className="box">
                                    <div className="title text-lg">
                                        Sort by Location
                                    </div>
                                    <div className="wrapper">
                                        <div className="search-group">
                                            <input onKeyPress={handleLocationFilter} value={locationSearchInput} onChange={(e:ChangeEvent<HTMLInputElement>) => setLocationSearchInput(e.currentTarget.value)} placeholder="Search..." type="text"/>
                                            <span className="icon">
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.83105 10.6621C8.49917 10.6621 10.6621 8.49917 10.6621 5.83105C10.6621 3.16294 8.49917 1 5.83105 1C3.16294 1 1 3.16294 1 5.83105C1 8.49917 3.16294 10.6621 5.83105 10.6621Z" stroke="#41BCFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M13.4226 13.2501L10.4205 10.2479" stroke="#41BCFE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </span>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }
            
            <section className="sort-result mt-5 mb-5">
                <header>
                    <div className="title">
                        Showing Results for {tableResultType == TableResultTypes.Houses ? "Houses" : "Lands"}
                    </div>
                    
                    <div className="actions">
                    <div className="switcher">
                        <ToggleButton status={true} optionOne={{text: "Avaiable Land", value:0, callback: () => settableResultType(TableResultTypes.Lands)}} optionTwo={{text: "Built Houses", value: 1, callback: () => settableResultType(TableResultTypes.Houses)}} />
                    </div>
                    </div>
                </header>
                <div className="listing table-responsive">
                <table className="table c-table">
                    <thead>
                        <tr>
                        {tableResultColumns.map((element:any, index:number) => {
                            return <th key={index} scope="col" className={index == tableResultColumns.length -1 ? "text-center" : ""}>{ element }</th>
                        })}
                        {/* <th className="selection"><input className="form-check-input" checked type="checkbox"/></th>
                        <th scope="col">Name</th>
                        <th scope="col">Location</th>
                        <th scope="col">Rooms</th>
                        <th scope="col">Reserved?</th>
                        <th className="text-center" scope="col">Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {/* House Data */}
                        { tableResultType == TableResultTypes.Houses && tableResultData.map((element:any, index:number) => {
                            return <tr key={index}>
                            <td className="selection"><input onClick={(e) => console.log(e.currentTarget.value)} className="form-check-input" type="checkbox"/></td>
                            <td onClick={ () => {setTableSelected(element);setFullSheetVisible(true)}} className="img-location">
                                <img width="70px" src="/940_Pine.png" alt=""/><span>{ element.house_name }</span> 
                            </td>
                            <td>{ element.house_address }</td>
                            <td>{ element.room }</td>
                            <td><span className="badge bg-primary">{ element.room_reserved ? "Yes" : "No" }</span></td>
                            <td className="text-center">
                            { element.is_short_listed && element.is_short_listed.data[0] == 1 ?
                                <Button theme="secondary" type="" className="me-2">Shortlisted</Button> : 
                                <Button bg={theme.hex} type="" className="me-2">Shortlist</Button>
                                }
                            </td>
                        </tr>
                        })}
                        {/* Land Data */}
                        { loading == false && tableResultType == TableResultTypes.Lands && tableResultData.map((element:any, index:number) => {
                            return <tr key={index}>
                            <td className="selection"><input className="form-check-input" type="checkbox"/></td>
                            <td onClick={ () => {setTableSelected(element);setLandInfoVisible(true)}}>
                                <span>{ element.land_address }</span> 
                            </td>
                            <td>{ element.land_area }</td>
                            <td>{ element.owner_info }</td>
                            <td className="text-center">
                            { element.is_shortlisted && element.is_shortlisted.data[0] == 1 ?
                                <Button theme="secondary" type="" className="me-2">Shortlisted</Button> : 
                                <Button bg={theme.hex} type="" className="me-2">Shortlist</Button>
                                }
                            </td>
                        </tr>
                        })}
                        {/* <tr onClick={ () => setLandInfoVisible(true)}>
                            <td className="selection"><input className="form-check-input" type="checkbox"/></td>
                            <td className="img-location">
                                <img width="70px" src="/940_Pine.png" alt=""/><span>3 Lancaster Street, Strathpine, QL</span> 
                            </td>
                            <td>$119,000</td>
                            <td>@900 m2</td>
                            <td>David Bowie</td>
                            <td className="text-center"><Button theme="secondary" type="" className="me-2">Shortlisted</Button></td>
                        </tr>
                        <tr onClick={ () => setLandInfoVisible(true)}>
                            <td className="selection"><input className="form-check-input" type="checkbox"/></td>
                            <td className="img-location">
                                <img width="70px" src="/940_Pine.png" alt=""/><span>3 Lancaster Street, Strathpine, QL</span> 
                            </td>
                            <td>$119,000</td>
                            <td>@900 m2</td>
                            <td>David Bowie</td>
                            <td className="text-center"><Button bg={theme.hex} type="" className="me-2">Shortlist</Button></td>
                        </tr> */}
                    </tbody>
                    </table>
                    
                </div>
            </section>
        </div>
        { slideSheetVisible && 
            <CreatePackage onClose={() => setSlideSheetVisible(!slideSheetVisible)} />
        }
        { fullSheetVisible && 
            <FullSheet onClose={() => setFullSheetVisible(false)} title="House Info">
                <HouseInfo data={tableSelected}/>
            <div className="land-details-box d-none">
                
                <div className="row g-0">
                    <div className="col-md-6">
                        <div className="img-wrapper">
                            <img width="100%" src="/house.png" alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                    <div className="left-bar">
                <table className="table table-responsive c-table">
                    <thead>
                        <tr>
                        <th scope="col">House & Land Area(m2)</th>
                        <th scope="col">Total Package</th>
                        <th scope="col">Gross Yield%</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>H: 900 L: 800</td>
                            <td>$1,20,900</td>
                            <td>12%</td>
                        </tr>
                    </tbody>
                    </table>
                    <div className="text-lg-sm title-second">House cum Plan Features</div>

                    <div className="points">
                        <ul>
                            <li>
                            6 Beds 6 Bathroom 4 Cars
                            </li>
                            <li>
                            Close to Communtiy Center
                            </li>
                            <li>
                            600m from care facility
                            </li>
                        </ul>
                    </div>
                </div>
                    </div>
                </div>
                
                <div>
                    <div className="land-name medium-title">8502 Preston Rd. Inglewood, Maine 98380</div>
                    <div className="land-info-title">
                        <span className="text">Plan Info</span>
                        <hr/>
                    </div>
                    <div className="land-info-title small-title">Platinum HS</div>
                    <Button className="me-2" type="outlined">Matterport</Button>
                    <Button type="rounded">Shortlist</Button>
                </div>
            </div>
        </FullSheet>
        }
        {landInfoVisible && 
            <FullSheet onClose={() => setLandInfoVisible(false)} title="Land Info">
                <LandInfo data={tableSelected}/>
            </FullSheet>
        }
        { addLandVisible && <AddLand onClose={() => setAddLandVisible(false)}/>}
    </>
}