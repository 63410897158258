import React from 'react';
import styled from 'styled-components'
import './style.scss'
import { useTheme, Theme, Color } from '../../context/AppContext';

interface Props {
    bg?: string
  }

//   type Props = {
//     title: string,
//     children: JSX.Element,
//   };

    const isCircleAndSmall = (props:any) => {
        if(props.type == "circle" && props.size == "small"){
            return true;
        }
        return false;
    }

    const isRoundedAndSmall = (props:any) => {
        if(props.type == "rounded" && props.size == "small"){
            return true;
        }
        return false;
    }

    const height = (props:any) => {
        if(isCircleAndSmall(props)){
            // return "auto";
            return "32px";
        } else if(props.type == "circle"){
            return "auto";
            // return "48px";
        } else if(props.type == "sm"){
            return "auto";
        }
        return "auto";
    }

    const width = (props:any) => {
        if(isCircleAndSmall(props)){
            return "32px";
        } else if(props.type == "circle"){
            return "48px";
        }
        return "auto";
    }

    const padding = (props:any) => {
        if(isCircleAndSmall(props)){
            return "0";
        } else if(props.type == "circle"){
            return "14px 15px";
        } else if(props.type == "sm"){
            return "10.5px 28px";
        }
        return "14px 28px";
    }

    const borderRadius = (props:any) => {
        if(props.type == "circle"){
            return "50%";
        } else if( props.type == "rounded") {
            return "6px";
        } else if(props.type == "sm"){
            return "30px";
        } else {
            return "30px"
        }
    }

    const fontSettings = (props:any) => {
        if( props.type == "rounded") {
            return {
                weight: "500",
                size: "14px",
                height: "15px",
            }
        } else if(props.type == "sm"){
            return {
                weight: "600",
                size: "13px",
                height: "18px",
            }
        } else {
            return {
                weight: "500",
                size: "14px",
                height: "132.2%",
            }
        }
    }

    const bgColor = (props:any, color:Color) => {
        if(props.type == "rounded-sm" || props.theme=="secondary"){
            return color.secondary.hex;
        }else if(props.type == "outlined"){
            return "transparent";
        }else {
            return color.hex;
        }
    }

    const border = (props:any, color:Color) => {
        if(props.type == "outlined" && !props.borderColor){
            return "1px solid " + color.hex;
        } else if(props.borderColor){
            return "1px solid " + props.borderColor;
        } else {
            return "none";
        }
    }

    const fontColor = (props:any) => {
        if(props.type == "outlined" && !props.borderColor){
            return props.theme.hex;
        }
        return "#fff";
    }

    // ${props => props.circle ? "50%" : "30px"}
    const StyledButton = styled.button<any>`
        border-radius: ${props => borderRadius(props)};
        background: ${props => props.bg};
        border: ${props => props.border};
        height: ${ props => height(props)};
        width: ${ props => width(props)} };
        color: ${ props => props.color ? props.color : fontColor(props) };
        padding: ${props => padding(props)};
        font-style: normal;
        font-weight: ${ props => fontSettings(props).weight };
        font-size: ${ props => fontSettings(props).size };
        line-height: ${ props => fontSettings(props).height };
    `;

    const Icon = styled.span<any>`
        margin-left: ${props => props.type == "circle" ? "0" : "15px"};
        vertical-align:  ${props => props.type == "circle" ? "text-bottom" : "initial"};
    `;

    function Button(props:any) {
        const { theme, setTheme } = useTheme();
        return(
        <StyledButton {...props} theme={theme} border={border(props, theme)} bg={props.bg ? props.bg : bgColor(props, theme)}><span>{ props.children }</span>
        { props.icon ? <Icon type={props.type}>{ props.icon }</Icon> : null }
        </StyledButton>
  )};



  export default Button;