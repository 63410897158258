import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useTheme, Theme } from '../../context/AppContext';

const FileIcon = <svg width="auto" height="auto" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.75 1H3.75C3.02065 1 2.32118 1.28973 1.80546 1.80546C1.28973 2.32118 1 3.02065 1 3.75V25.75C1 26.4793 1.28973 27.1788 1.80546 27.6945C2.32118 28.2103 3.02065 28.5 3.75 28.5H20.25C20.9793 28.5 21.6788 28.2103 22.1945 27.6945C22.7103 27.1788 23 26.4793 23 25.75V9.25L14.75 1Z" stroke="#6C5DD3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.75 1V9.25H23" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.4996 16.1249H6.49963" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.4996 21.625H6.49963" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.24963 10.6249H7.87463H6.49963" stroke="#6C5DD3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


const MainWrapper = styled.div`
    background: #FAFAFA;
    border-radius: 6px;
    display: flex;
    padding: 21px 29px;
    align-items: baseline;

    display: flex;
    padding: 21px 29px;
    align-items: baseline;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    cursor: pointer;
`;

const Header = styled.div`
    margin-right: 15px;
    width: 5%;
`;

const TitleWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #B2B3BD;
`;

const ContentWrapper = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    margin-top: 15.5px;
    color: #454545;

`;

const IconWrapper = styled.div<any>`
    margin-right: 15px;
    width: 100%;

    path{
        stroke: ${ props => props.theme }
    }
`;

type props = {
    name: string,
    theme?: string,
}

export default function FileLarge(props: props) {
    const { theme, setTheme } = useTheme();

    return <>
        <MainWrapper>
            <IconWrapper theme={theme.hex}>
                {FileIcon}
            </IconWrapper>
            <ContentWrapper>
                { props.name }
            </ContentWrapper>
        </MainWrapper>
    </>

}



