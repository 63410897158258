import React, { useState } from 'react';
import styled from 'styled-components';
import { isPropertySignature } from 'typescript';
import InfoCard from './components/InfoCard';
import Specification from './components/Specification';
import Map from '../common/Map';


const SpecificationsContainer = styled.div`
    margin: 52px 0;
`;

const Line = styled.hr`
    background: #E4E4E4;
    margin-top: 57px;
    margin-bottom: 37px;
`;

const DetailTitle = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #B2B3BD;
    max-width: 277px;
    display: inline-block;
    margin-bottom:31px;
`;

const List = styled.ul`
    padding-left: 17px;
`;

const Container = styled.div`
    margin-left:29px;
`;

function LandInfo(props:any){
    const [mapVisible, setMapVisible] = useState(false);
    if(props.data == null){
        return <></>;
    }
    

    return <>
        { mapVisible && 
            <Map onClose={() => setMapVisible(false)} position={[props.data.land_lat, props.data.land_lng]}/>
        }
        <div className="row d-block-md g-0">
            <div className="col-md-4 col-sm-12 col-xs-12">
                <InfoCard 
                    image="/map.png" 
                    imgButtonText="Open Maps" 
                    title={props.data.land_address}
                    detailTitle="Owner Info"
                    details={props.data.owner_info}
                    imageButtonClick={() => setMapVisible(!mapVisible)}
                />
            </div>
            <div className="col-md-6">
                <Container>
                    <SpecificationsContainer>
                        <div className="row g-0">
                            <div className="col-md-4">
                                <Specification title="Land Area(m2)" value={props.data.land_area} />
                            </div>
                            <div className="col-md-4">
                                <Specification title="Price" value="NA" />
                            </div>
                            <div className="col-md-4">
                                <Specification title="Status" value={props.data.is_shortlisted && props.data.is_shortlisted.data[0] == 0 ? "Unavailable" : "Avaiable"} />
                            </div>
                        </div>
                    </SpecificationsContainer>
                    <Line/>
                    <DetailTitle>Land Features</DetailTitle>
                    <List>
                        {props.data.land_features}
                        {/* <li>2 Mins Away from nearest Police Station</li>
                        <li>Close to Communtiy Center</li>
                        <li>600m from care facility</li> */}
                    </List>
                </Container>
            </div>
        </div>
    </>
}

export default LandInfo;