import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import './welcome.scss';
import logo from '../../assets/images/logo.svg';
import { useTheme, Theme, Color, useAppContext } from '../../context/AppContext';
import {RoleEnum} from '../../platform/enums/role';


const BGCircle = styled.div`
    position: fixed;
    width: 1264px;
    height: 1264px;
    z-index: -11;
    background: linear-gradient(180deg,#41BCFE 0%,#8FD8FE 100%);
    border-radius: 1023.84px;
    left: calc(50% - 1264px/2 - 327px);
    top: calc(50% - 1264px/2);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    animation-name: backgroundColorPalette;
	animation-duration: 20s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
    animation-timing-function: linear; 
    /* transition: all ease-in-out 0.1s; */

    &::after{
        content: '';
        width: 250px;
        height: 100%;
        position: absolute;
        top: 40%;
        left: 55%;
        background-repeat: no-repeat;
        background-size: contain;
        /* background-image: url('/static/icons/av-sales-login.svg'); */
        animation-name: backgroundImages;
        animation-duration: 20s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); 
        /* transition: all ease-in-out 0.1s; */
    }
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 52.8378px;
    line-height: 105%;
    /* or 55px */
    max-width: 634.05px;
    letter-spacing: -1.32095px;
    margin-bottom: 40px;
    /* White */

    color: #FFFFFF;

    `;

const PageDescription = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 22.4561px;
    line-height: 27px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    color: #FFFFFF;
    max-width: 446.48px;
`;

function Welcome(props:any){

    const { theme, setTheme } = useTheme();
    const { user, setUser } = useAppContext();
    
    const history = useHistory();

    const Container = styled.div`
        display: block;
        width:100%;
    `;

    

    const Value = styled.div`
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 132%;
        color: #11142D;
    `;

    const LoginBadge = styled.div<any>`
        /* display:inline-block; */
        min-width: 330px;
        width: 330px;
        height: 122.02px;
        border-radius: 81px;
        background: linear-gradient(180deg, ${ props => props.bg } 0%, ${props => props.bgsec} 100%);
        /* top: -85px; */
        /* position: relative; */
        padding:1px;
        margin-bottom:42px;
        position: relative;
        left:0;
        cursor: pointer;
        transition: all 0.3s;
        &:hover{
            left: 45px;
            transition: all 0.3s;
        }
    `;

    const LoginBadgeCircle = styled.div`
        background: #FFFFFF;
        box-shadow: 0px 8px 23px rgba(77,76,76,0.06);
        width: 82px;
        height: 82px;
        border-radius: 50%;
        margin-top: 16px;
        margin-bottom: 23px;
        padding: 21px 21px;
        margin-left: 20px;
        margin-right: 20px;
        display: inline-block;
    `;

    const LoginBadgeText = styled.span`
        font-size: 22px;
        line-height: 27px;
        color: rgba(255, 255, 255, 0.9);
    `;

    const LoginBadgeIcon = styled.img`
        
    `;

    const Logo = styled.img`
        width:114px;
        position: fixed;
        top: 75px;
        left: 81px;
    `;

    const Button = styled.button`
        background: #FFFFFF;
        border-radius: 50px;
        padding: 20px 60px;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        border: none;
        text-align: center;
        outline: none;
        color: rgb(33 33 44);
    `;

    const handleThemeClick = (name:string, selection:any) => {
        setTheme(selection);
        // setUser(null)
        // history.push('/auth/login', {department: selection.role});
        console.log(selection); 
        history.push({pathname: '/auth/login', state: {department: name}})

    }

    return <>
        <div className="welcome-wrapper-new">
            <Container>
                <Logo src="/static/icons/logo-new.svg" />
                <BGCircle/>
                <div className="row content-wrapper">
                    <div className="col-md-6">
                        <div className="page-info-wrapper">
                            <Title>Please select a Deapartment.</Title>
                            <PageDescription>Pick your role in the company to be able to access the log in screen.</PageDescription>
                            <PageDescription>Don’t have account yet?</PageDescription>
                            <Button onClick={() => history.replace('/auth/signup')}>Create Account</Button>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 badges-wrapper">
                        <LoginBadge onClick={() => handleThemeClick("Sales", Theme.Sales)} bg={Theme.Sales.hex} bgsec="#0587F6">
                            <LoginBadgeCircle>
                                <LoginBadgeIcon src="/static/icons/login-sales.svg" />
                            </LoginBadgeCircle>
                            <LoginBadgeText>Sales</LoginBadgeText>
                        </LoginBadge>
                        <LoginBadge onClick={() => handleThemeClick("Build", Theme.Build)} bg={Theme.Build.hex} bgsec="#32CB84">
                            <LoginBadgeCircle>
                                <LoginBadgeIcon width="40px" src="/static/icons/login-build.svg" />
                            </LoginBadgeCircle>
                            <LoginBadgeText>Build</LoginBadgeText>
                        </LoginBadge>
                        <LoginBadge onClick={() => handleThemeClick("Tenant", Theme.Tenant)}  bg={Theme.Tenant.hex} bgsec={Theme.Tenant.hex}>
                            <LoginBadgeCircle>
                                <LoginBadgeIcon src="/static/icons/login-tenant.svg" />
                            </LoginBadgeCircle>
                            <LoginBadgeText>Tenant</LoginBadgeText>
                        </LoginBadge>
                        <LoginBadge onClick={() => handleThemeClick("Investor", Theme.Investor)} bg={Theme.Investor.hex} bgsec={Theme.Investor.hex}>
                            <LoginBadgeCircle>
                                <LoginBadgeIcon src="/static/icons/login-investor.svg" />
                            </LoginBadgeCircle>
                            <LoginBadgeText>Investor</LoginBadgeText>
                        </LoginBadge>
                    </div>
                </div>
            </Container>
        </div>
    </>
}

export default Welcome;