import React, { useState } from 'react';
import styled from 'styled-components';
import './style.scss'
import { useTheme, Theme } from '../../context/AppContext';
import { Pannellum } from "pannellum-react";
import Button from '../../components/buttons/Buttons';

const Container = styled.div<any>`
    background: rgba(${props => props.theme.rgb}, 0.1);
    border-radius: 47px;
    position: fixed;
    top: 0;
    z-index:2;
    left: 0;
    width: 100%;
    height: 100vh;
    -webkit-box-shadow: inset 10px 128px 49px -19px rgba(0,0,0,0.6);
    -moz-box-shadow: inset 10px 128px 49px -19px rgba(0,0,0,0.6);
    box-shadow: inset 10px 128px 49px -19px rgba(0,0,0,0.6);
`;

const SelectionBackground = styled.span<any>`
    border-radius: 30px;
    padding: 25px 92px;
    position: absolute;
    background-color: ${props => props.theme.hex};
    top: 2px;
    z-index: -1;
    transition: right 1000ms;
    animation-delay: 1s;
    ${ props => props.status ? "right: 2px;" : "right:48.4%"};
`;

const ToolBox = styled.div`
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    padding: 28px 66px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
`;

const Text = styled.span`
    color: #fff;
    margin-left: 19px;
`

function ImageViewer(props:any){

    const [selected, setSelected] = useState("");
    const [status, setStatus] = useState(false);
    const { theme, setTheme } = useTheme();

    

    // const ButtonSecondary = styled(Button)`
    //     background: rgba(108, 93, 211, 0.08);
    // `;

    return <>
        <Container className="image-viewer" theme={theme}>
        <ToolBox>
            <span>
                <Button onClick={props.onClose} type={"circle"} size="small" icon={<svg width="8px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path fill="#fff"
                d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                ></path>
                </svg>}></Button>
                <Text>Go Back</Text>
            </span>
            <span>
                <Button bg="#fff" type={"circle"} size="small" icon={<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.0876 11.1448L17.8478 13.7016C17.8207 13.7229 17.7881 13.7362 17.7538 13.7399C17.7195 13.7435 17.6849 13.7375 17.6539 13.7224C17.6229 13.7073 17.5968 13.6837 17.5785 13.6545C17.5603 13.6252 17.5506 13.5914 17.5507 13.5569V11.9141H11.914V17.5508H13.5593C13.7117 17.5508 13.798 17.7286 13.704 17.8479L11.1447 21.0878C11.1277 21.1097 11.106 21.1274 11.0811 21.1396C11.0563 21.1519 11.0289 21.1582 11.0012 21.1582C10.9735 21.1582 10.9462 21.1519 10.9213 21.1396C10.8964 21.1274 10.8747 21.1097 10.8578 21.0878L8.29838 17.8479C8.27706 17.8208 8.2638 17.7882 8.26013 17.7539C8.25646 17.7197 8.26253 17.685 8.27763 17.654C8.29274 17.623 8.31627 17.5969 8.34554 17.5786C8.3748 17.5604 8.40862 17.5508 8.4431 17.5508H10.0859V11.9141H4.44916V13.5594C4.44916 13.7118 4.27142 13.7981 4.15209 13.7042L0.912244 11.1448C0.890321 11.1278 0.872571 11.1061 0.860359 11.0812C0.848147 11.0564 0.841797 11.029 0.841797 11.0013C0.841797 10.9736 0.848147 10.9463 0.860359 10.9214C0.872571 10.8966 0.890321 10.8748 0.912244 10.8579L4.14955 8.2985C4.26889 8.20455 4.44662 8.28834 4.44662 8.44322V10.086H10.0833V4.44928H8.43803C8.28568 4.44928 8.19935 4.27155 8.2933 4.15221L10.8527 0.914904C10.9263 0.820959 11.0685 0.820959 11.1396 0.914904L13.699 4.15221C13.7929 4.27155 13.7091 4.44928 13.5542 4.44928H11.914V10.086H17.5507V8.44069C17.5507 8.28834 17.7285 8.20201 17.8478 8.29596L21.0851 10.8553C21.107 10.8725 21.1247 10.8944 21.1369 10.9193C21.1492 10.9443 21.1557 10.9717 21.1559 10.9994C21.1562 11.0272 21.1502 11.0547 21.1383 11.0799C21.1265 11.1051 21.1092 11.1272 21.0876 11.1448Z" fill="#11142D"/>
</svg>
}></Button>
                <Text>Click and Drag to Rotate</Text>
            </span>
        </ToolBox>
        <Pannellum
            width="100%"
            height="100vh"
            image="/room.jpeg"
            pitch={10}
            yaw={90}
            hfov={110}
            autoLoad
            showControls={false}
            showZoomCtrl={false}
            >
            
            </Pannellum>
        </Container>
    </>
}

export default ImageViewer;