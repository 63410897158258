import React from 'react';
import styled, {keyframes} from 'styled-components'
import { useTheme, Theme, appContextType, useAppContext } from '../context/AppContext';
import { useHistory } from "react-router-dom";

    const Wrapper = styled.div`
        background-color:#FAFAFA;
        padding: 45px 35px;
    `;

    const Box = styled.div`
    font-size: 1.5em;
    text-align: center;
    display:inline-block;
    `;

    const Icon = styled.div`
        cursor: pointer;
    `;

    const IconText = styled.div`
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        margin-top: 10px;
    `;


export default function SidebarSetting(props: any) {
    const { theme, setTheme } = useTheme();
    const { user, setUser } = useAppContext();
    const history = useHistory();

    const logout = () => {
        setUser(null);
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        history.replace('/');
    }
    
    return <>
        <Wrapper>
            <Box>
                <Icon>
                    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.08" cx="22.7126" cy="22.7126" r="22.7126" fill={theme.hex} />
                        <path fillRule="evenodd" clipRule="evenodd" d="M32.1588 18.3413L31.5013 17.2003C30.945 16.2348 29.7123 15.9018 28.7455 16.4557V16.4557C28.2853 16.7268 27.7362 16.8037 27.2192 16.6695C26.7022 16.5353 26.2599 16.2009 25.9897 15.7402C25.8159 15.4473 25.7225 15.1138 25.719 14.7733V14.7733C25.7346 14.2273 25.5287 13.6982 25.148 13.3066C24.7673 12.915 24.2442 12.6941 23.6981 12.6943H22.3734C21.8383 12.6943 21.3252 12.9076 20.9478 13.2868C20.5703 13.6661 20.3595 14.1802 20.3621 14.7152V14.7152C20.3463 15.82 19.4461 16.7072 18.3412 16.7071C18.0007 16.7036 17.6671 16.6102 17.3743 16.4364V16.4364C16.4075 15.8824 15.1748 16.2155 14.6185 17.1809L13.9126 18.3413C13.357 19.3055 13.6856 20.5375 14.6475 21.097V21.097C15.2728 21.458 15.658 22.1252 15.658 22.8472C15.658 23.5692 15.2728 24.2364 14.6475 24.5974V24.5974C13.6868 25.1532 13.3579 26.3821 13.9126 27.3435V27.3435L14.5798 28.4941C14.8404 28.9644 15.2777 29.3114 15.7949 29.4584C16.3121 29.6054 16.8666 29.5403 17.3356 29.2773V29.2773C17.7967 29.0083 18.3461 28.9346 18.8618 29.0726C19.3774 29.2106 19.8166 29.5489 20.0817 30.0122C20.2555 30.3051 20.3489 30.6386 20.3524 30.9792V30.9792C20.3524 32.0953 21.2572 33.0001 22.3734 33.0001H23.6981C24.8104 33.0001 25.7136 32.1012 25.719 30.9888V30.9888C25.7164 30.4521 25.9285 29.9365 26.308 29.557C26.6876 29.1774 27.2031 28.9653 27.7399 28.9679C28.0796 28.977 28.4118 29.07 28.7068 29.2387V29.2387C29.671 29.7943 30.903 29.4657 31.4626 28.5038V28.5038L32.1588 27.3435C32.4283 26.8809 32.5022 26.33 32.3643 25.8128C32.2264 25.2956 31.8879 24.8546 31.4239 24.5877V24.5877C30.9599 24.3207 30.6215 23.8798 30.4835 23.3626C30.3456 22.8453 30.4196 22.2945 30.689 21.8319C30.8643 21.526 31.1179 21.2723 31.4239 21.097V21.097C32.3801 20.5378 32.7078 19.313 32.1588 18.3509V18.3509V18.3413Z" stroke={theme.hex} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <circle cx="23.0405" cy="22.8471" r="2.78479" stroke={theme.hex} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <IconText>Settings</IconText>
                </Icon>
            </Box>
            <Box style={{margin: "0 45px"}}>
                <Icon>
                    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.08" cx="23.1379" cy="22.7126" r="22.7126" fill={theme.hex} />
                        <path d="M23.7586 14.498C19.1636 14.498 15.4253 18.2364 15.4253 22.8314V26.2839C15.4253 27.1372 16.1728 27.8314 17.092 27.8314H17.9253C18.1463 27.8314 18.3583 27.7436 18.5145 27.5873C18.6708 27.431 18.7586 27.2191 18.7586 26.998V22.7122C18.7586 22.4912 18.6708 22.2792 18.5145 22.123C18.3583 21.9667 18.1463 21.8789 17.9253 21.8789H17.1686C17.632 18.6539 20.407 16.1647 23.7586 16.1647C27.1103 16.1647 29.8853 18.6539 30.3486 21.8789H29.592C29.3709 21.8789 29.159 21.9667 29.0027 22.123C28.8464 22.2792 28.7586 22.4912 28.7586 22.7122V27.8314C28.7586 28.7505 28.0111 29.498 27.092 29.498H25.4253V28.6647H22.092V31.1647H27.092C28.9303 31.1647 30.4253 29.6697 30.4253 27.8314C31.3445 27.8314 32.092 27.1372 32.092 26.2839V22.8314C32.092 18.2364 28.3536 14.498 23.7586 14.498Z" fill={theme.hex} />
                    </svg>
                    <IconText>Support</IconText>
                </Icon>
            </Box>
            <Box>
                <Icon onClick={logout}>
                    <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.08" cx="23.5632" cy="22.7126" r="22.7126" fill={theme.hex} />
                        <path d="M24.3506 22.651H15.893L17.8635 20.6871C18.0896 20.4469 18.0774 20.0695 17.8363 19.8442C17.606 19.629 17.2475 19.6291 17.0174 19.8445L14.0254 22.8263C13.8546 22.9969 13.8036 23.2531 13.8962 23.4758C13.9261 23.5482 13.97 23.6141 14.0254 23.6696L17.0173 26.6514C17.2551 26.8802 17.6339 26.8736 17.8635 26.6367C18.0874 26.4056 18.0874 26.0392 17.8635 25.8081L15.8929 23.8437H24.3506V22.651Z" fill={theme.hex} />
                        <path d="M20.6963 22.6508V17.2835C20.6963 16.2954 21.5 15.4944 22.4915 15.4944H30.2706C31.262 15.4944 32.0657 16.2954 32.0657 17.2835V29.2108C32.0657 30.1989 31.262 30.9999 30.2706 30.9999H22.4915C21.5 30.9999 20.6963 30.1989 20.6963 29.2108V23.1933" stroke={theme.hex} strokeWidth="1.5" />
                    </svg>
                    <IconText>Logout</IconText>
                </Icon>
            </Box>
        </Wrapper>
    </>
}