import React, { useState } from 'react';
import styled from 'styled-components';
// import './style.scss'
import { useTheme, Theme } from '../../context/AppContext';
import MainHeader from '../../components/common/MainHeader';
import Button from '../../components/buttons/Buttons';
import Table from '../../components/common/Table';
import MiniCard from '../../components/common/MiniCard';
import HouseInfo from '../tenant/HouseInfo';
import Input from '../../components/common/Input';
import SlideSheet from '../../components/slideSheet/SlideSheet';
import FileLarge from '../../components/common/FileLarge';

enum COMPONENTS {
    main,
    houseInfo
}

const Container = styled.div`
    padding: 0px 43px;
`;

const MainWrapper = styled.div`
    flex-wrap: wrap;
    width: 100%;
`;

const FilterWrapper = styled.div`
    padding: 42px 0px;
`;

const FilterLabel = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #808191;
    margin-bottom: 24px;
`;

const FilterIcon =  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 1H1L6.6 7.30667V11.6667L9.4 13V7.30667L15 1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

const SearchIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.83105 10.6621C8.49917 10.6621 10.6621 8.49917 10.6621 5.83105C10.6621 3.16294 8.49917 1 5.83105 1C3.16294 1 1 3.16294 1 5.83105C1 8.49917 3.16294 10.6621 5.83105 10.6621Z" stroke="#6C5DD3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.4226 13.2501L10.4204 10.2479" stroke="#6C5DD3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const AddIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.0001 1.99999V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.00009 9H16.0001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const EditIcon = <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.77853 3.13092H2.60375C2.21163 3.13092 1.83556 3.28669 1.55829 3.56397C1.28102 3.84124 1.12524 4.2173 1.12524 4.60943V14.959C1.12524 15.3511 1.28102 15.7272 1.55829 16.0045C1.83556 16.2817 2.21163 16.4375 2.60375 16.4375H12.9533C13.3454 16.4375 13.7215 16.2817 13.9988 16.0045C14.276 15.7272 14.4318 15.3511 14.4318 14.959V9.78421" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.323 2.02206C13.6171 1.72796 14.0159 1.56274 14.4318 1.56274C14.8478 1.56274 15.2466 1.72796 15.5407 2.02206C15.8348 2.31615 16 2.71503 16 3.13094C16 3.54685 15.8348 3.94573 15.5407 4.23982L8.51781 11.2627L5.56079 12.002L6.30005 9.04497L13.323 2.02206Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>



function FileManager(props:any){

    const [selected, setSelected] = useState("");
    const [status, setStatus] = useState(false);
    const [houseInfoVisible, setHouseInfoVisible] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);
    const [addWaiteeVisible, setAddWaiteeVisible] = useState(false);
    const [currentView, setCurrentView] = useState<COMPONENTS>(COMPONENTS.main);
    const { theme, setTheme } = useTheme();

    const headerButtons = () => {
        return [
            {
                name: "",
                type: "circle",
                icon: FilterIcon,
                onClick: () => setFilterVisible(!filterVisible)
            },
            {
                name: "Add a Lead",
                type: "",
                icon: AddIcon,
                onClick: () => setAddWaiteeVisible(true)
            }
        ]
    }

    const main = () => {
        return  <>
                    <MainHeader 
                        title="File Manager" 
                        info="See the list of all the houses, info related to it and edit the tenants." 
                        buttons={headerButtons()}
                    />
                    <Container>
                        { filterVisible && 
                            <FilterWrapper>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <FilterLabel>Search by file name</FilterLabel>
                                        <Input icon={SearchIcon} placeholder="Type file name" />
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <FilterLabel>&nbsp;</FilterLabel>
                                        <Button type="outlined">Clear All Filter</Button>
                                    </div>
                                </div>
                            </div>
                        </FilterWrapper>
                        }
                        <div className="row mt-4">
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Letter of Offer.pdf"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Ndis Participation form.docx"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Letter of Offer.pdf"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Ndis Participation form.docx"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Ndis Participation form.docx"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Letter of Offer.pdf"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Ndis Participation form.docx"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Letter of Offer.pdf"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Ndis Participation form.docx"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Ndis Participation form.docx"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Letter of Offer.pdf"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Ndis Participation form.docx"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Letter of Offer.pdf"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Ndis Participation form.docx"/>
                            </div>
                            <div className="col-md-2 mb-3">
                                <FileLarge name="Ndis Participation form.docx"/>
                            </div>
                        </div>
                    </Container>
                    { addWaiteeVisible && 
                        <SlideSheet title="Lead Info" maxWidth="437px" onClose={() => setAddWaiteeVisible(false)} action={{icon:EditIcon, buttonType:"circle"}} bordered={false}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                        <Input value="Delsey Wallace" label="Full Name" placeholder="Full Name" />
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <Input value="+ 71 7638 7263" label="Phone Number" placeholder="Phone Number" />
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <Input value="Brisbane   (4005)" label="Email Address" placeholder="Email Address" />
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <Input value="Support Worker" label="Area/Location" placeholder="Area/Location" />
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <Input value="Client" label="On Behalf of" placeholder="On Behalf of" />
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <Input label="Additional Notes" placeholder="Additional Notes" />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-12 text-center">
                                        <Button>Notify</Button>
                                    </div>
                                </div>
                            </div>
                        </SlideSheet>
                    }
                 </>
    }

    const showContent = () => {
        switch (currentView) {
            case COMPONENTS.main:
                return main();
                break;
            case COMPONENTS.houseInfo:
                return <HouseInfo data="" onClose={() => setCurrentView(COMPONENTS.main)} />;
            default:
                break;
        }
    }

    return <>
        <MainWrapper>
            {showContent()}
        </MainWrapper>
    </>
}

export default FileManager;