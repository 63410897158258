import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme, Theme } from '../../context/AppContext';

const Container = styled.div`
    margin-bottom: 54px;
`;

const Text = styled.span<any>`
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.selected ? props.theme.hex : "#B2B3BD"};
    & + span:before {
        padding: 8px;
        color: #959595;
        content: url('/breadcrumbs.svg');
        margin-left:38px;
        margin-right:24px;
        }
`;

const Content = styled.div`
    margin-top: 30px;
`;

interface Data {
    id: number,
    text: string,
    content?: any,
    action?: {name: string, callback: Function},
    onClick?: Function
  }

function Breadcrumbs(props:any){

    const { theme, setTheme } = useTheme();
    const [selected, setSelected] = useState(props.selected | 1);
    
 
    const handleClick = () => {

    }

    const TextCollection = () => {
        return props.data.map(
            (i:Data, index:number) => 
                <Text 
                    key={index} 
                    theme={theme} 
                    onClick={i.onClick} 
                    selected={props.selected == i.id}>{i.text}</Text>
        )
    }

    return <>
        <Container>
            {TextCollection()}
            <Content>{ props.data.find((i:Data) => i.id == props.selected).content }</Content>
        </Container>
    </>
}

export default Breadcrumbs;