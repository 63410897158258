import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import Collapse from './Collaplse';
import ToggleButton from '../../components/buttons/ToggleButton';
import File from './File';

const MainWrapper = styled.div`

`;

const Header = styled.div`
    margin-bottom: 45px;
    text-align: center;
`;

const Image = styled.img`
    border-radius: 50%;
    max-width: 159px;
`;

const TitleWrapper = styled.div`
    margin-top: 20px;
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #11142D;
    margin-bottom: 5px;
`;

const Description = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #B2B3BD;
`;

const HR = styled.hr`
    opacity: 0.1;
    border: 1px solid #C7C7C7;
`;

const ActionsWrapper = styled.div`
    margin-top: 45px;
`;

const ContentWrapper = styled.div`

`;

const basicInfo = [
    {name: "DOB:", value: "23 Aug 1963"},
    {name: "Guardian Name:", value: "Desley Wallace"},
    {name: "Guardian Conatct:", value: "73638273"},
    {name: "Financial delegate:", value: "Public Trustee "}
]

const supprtInfo  = [
    {name: "Name:", value: "Gemma Ainsworth"},
    {name: "Conatct:", value: "0491 052 480"},
]

enum COMPONENTS {
    info,
    files
}

type props = {
    title: string,
    desc: string,
    image?: string
}

export default function TenantInfo(props: props) {
    const [view, setView] = useState(COMPONENTS.info);

    return <>
        <MainWrapper>
            <Header>
                <Image src="/static/icons/jan2.png" />
                <TitleWrapper>
                    <Title>Desley Wallace</Title>
                    <Description>Lives in Room 1</Description>
                </TitleWrapper>
                <ActionsWrapper>
                    <ToggleButton optionOne={{text: "Tenant’s Info", callback: () => setView(COMPONENTS.info)}} optionTwo={{text: "Tenant’s Files", callback: ()=> setView(COMPONENTS.files)}} />
                </ActionsWrapper>
            </Header>
            { view == COMPONENTS.info && 
                <ContentWrapper>
                    <Collapse title="Basic Info" status={true} data={basicInfo}/>
                    <HR/>
                    <Collapse title="Supprt Co-ordinator Info " status={true} data={supprtInfo}/>
                </ContentWrapper>
            }
            { view == COMPONENTS.files && 
                <ContentWrapper>
                    <Collapse title="Critical Documents" status={true}>
                        <File name="1. Ndis Participation form.docx"/>
                        <File name="2. Letter of offer.docx"/>
                    </Collapse>
                    <HR/>
                    <Collapse title="Other Documents" status={true}>
                        <File name="3. Tenancy Agreement.docx"/>
                        <File name="4. Pre Tenancy Interview.docx"/>
                    </Collapse>
                    
                </ContentWrapper>
            }
        </MainWrapper>
    </>

}

const ChevronIcon = <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7L6.925 1.075L12.85 7" stroke="#B2B3BD" strokeWidth="1.71212" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

