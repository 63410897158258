import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import './style.scss'
import { useTheme, Theme, useAppContext } from '../../context/AppContext';
import MainHeader from '../../components/common/MainHeader';
import Button from '../../components/buttons/Buttons';
import Table from '../../components/common/Table';
import MiniCard from '../../components/common/MiniCard';
import HouseInfo from '../tenant/HouseInfo';
import Input from '../../components/common/Input';
import SlideSheet from '../../components/slideSheet/SlideSheet';
import API from '../../platform/api/api';
import Loader from '../../components/common/Loader';

enum COMPONENTS {
    main,
    houseInfo
}

const Container = styled.div`
    padding: 0px 43px;
`;

const MainWrapper = styled.div`
    flex-wrap: wrap;
    width: 100%;
`;

const FilterWrapper = styled.div`
    padding: 42px 0px;
`;

const FilterLabel = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #808191;
    margin-bottom: 24px;
`;

const FilterIcon = <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 1H1L6.6 7.30667V11.6667L9.4 13V7.30667L15 1Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>;

const SearchIcon = <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.83105 10.6621C8.49917 10.6621 10.6621 8.49917 10.6621 5.83105C10.6621 3.16294 8.49917 1 5.83105 1C3.16294 1 1 3.16294 1 5.83105C1 8.49917 3.16294 10.6621 5.83105 10.6621Z" stroke="#6C5DD3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.4226 13.2501L10.4204 10.2479" stroke="#6C5DD3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const AddIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.0001 1.99999V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.00009 9H16.0001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const EditIcon = <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.77853 3.13092H2.60375C2.21163 3.13092 1.83556 3.28669 1.55829 3.56397C1.28102 3.84124 1.12524 4.2173 1.12524 4.60943V14.959C1.12524 15.3511 1.28102 15.7272 1.55829 16.0045C1.83556 16.2817 2.21163 16.4375 2.60375 16.4375H12.9533C13.3454 16.4375 13.7215 16.2817 13.9988 16.0045C14.276 15.7272 14.4318 15.3511 14.4318 14.959V9.78421" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.323 2.02206C13.6171 1.72796 14.0159 1.56274 14.4318 1.56274C14.8478 1.56274 15.2466 1.72796 15.5407 2.02206C15.8348 2.31615 16 2.71503 16 3.13094C16 3.54685 15.8348 3.94573 15.5407 4.23982L8.51781 11.2627L5.56079 12.002L6.30005 9.04497L13.323 2.02206Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>



function WaitList(props: any) {

    const [selected, setSelected] = useState("");
    const [status, setStatus] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);
    const [addWaiteeVisible, setAddWaiteeVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [slideSheetLoading, setSlideSheetLoading] = useState(false);

    // Form Data
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [location, setLocation] = useState("");
    const [behalfId, setBehalfId] = useState("");
    const [addisionalNotes, setAddisionalNotes] = useState("");
    const [createdBy, setCreatedBy] = useState("");

    // Data
    const [waitlist, setWaitlist] = useState([]);
    const [selectedWaitee, setSelectedWaitee]: any = useState(null);
    const [formTitle, setFormTitle] = useState("Add Waitee");

    const [currentView, setCurrentView] = useState<COMPONENTS>(COMPONENTS.main);
    const { theme, setTheme } = useTheme();
    const { user } = useAppContext();

    const headerButtons = () => {
        return [
            // {
            //     name: "",
            //     type: "circle",
            //     icon: FilterIcon,
            //     onClick: () => setFilterVisible(!filterVisible)
            // },
            {
                name: "Add a Waitee",
                type: "",
                icon: AddIcon,
                onClick: () => {
                    setAddWaiteeVisible(true);
                    setFormTitle("Add Waitee");
                    resetForm();
                }
            }
        ]
    }

    const tableData = {
        columns: ["Name", "Phone and E-Mail", "Location of Interest", "Inquiry on behalf of", "Action"],
        rows: [
            [
                "Jane Doe",
                "119 000 82793, jane@gmail.com",
                "Logan St, Brisbane",
                "Family member",
                <Button onClick={() => setCurrentView(COMPONENTS.houseInfo)}>Notify</Button>
            ],
            [
                "Jane Doe",
                "119 000 82793, jane@gmail.com",
                "Logan St, Brisbane",
                "Family member",
                <Button onClick={() => setCurrentView(COMPONENTS.houseInfo)}>Notify</Button>
            ],
            [
                "Jane Doe",
                "119 000 82793, jane@gmail.com",
                "Logan St, Brisbane",
                "Family member",
                <Button onClick={() => setCurrentView(COMPONENTS.houseInfo)}>Notify</Button>
            ],
            [
                "Jane Doe",
                "119 000 82793, jane@gmail.com",
                "Logan St, Brisbane",
                "Family member",
                <Button onClick={() => setCurrentView(COMPONENTS.houseInfo)}>Notify</Button>
            ],
            [
                "Jane Doe",
                "119 000 82793, jane@gmail.com",
                "Logan St, Brisbane",
                "Family member",
                <Button onClick={() => setCurrentView(COMPONENTS.houseInfo)}>Notify</Button>
            ],
            [
                "Jane Doe",
                "119 000 82793, jane@gmail.com",
                "Logan St, Brisbane",
                "Family member",
                <Button onClick={() => setCurrentView(COMPONENTS.houseInfo)}>Notify</Button>
            ],


        ]
    }

    const fetchWaitList = () => {
        setLoading(true);
        API.get('waitlist').then(res => {
            setLoading(false);
            setWaitlist(res.data.results);
        }).catch(error => {

        })
    }

    const resetForm = () => {
        setSelectedWaitee(null);
        setName("");
        setEmail("");
        setPhone("");
        setLocation("");
        setBehalfId("");
        setAddisionalNotes("")
    }

    const getDBFormatData = () => {
        return {
            name: name,
            phone_number: phone,
            email: email,
            location: location,
            behalf_id: 2,
            additional_notes: addisionalNotes,
            created_by: null,
        }
    }

    const createWaitee = () => {

        setSlideSheetLoading(true);

        API.post(
            'waitlist/create',
            getDBFormatData(),
            { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                fetchWaitList();
                setAddWaiteeVisible(false);
                // setNewTodoVisible(false);
                // setNewTodoText("");
                setSlideSheetLoading(false);
            }).catch(error => {

            })
    }

    const updateWaitee = () => {

        setSlideSheetLoading(true);

        API.patch(
            'waitlist/update/' + selectedWaitee.waitlist_id,
            getDBFormatData(),
            { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                fetchWaitList();
                setAddWaiteeVisible(false);
                // setNewTodoVisible(false);
                // setNewTodoText("");
                setSlideSheetLoading(false);
            }).catch(error => {

            })
    }

    const handleWaiteeSelection = (waitee: any) => {
        setFormTitle("Update Waitee");
        setSelectedWaitee(waitee);
        setName(waitee.name);
        setEmail(waitee.email);
        setPhone(waitee.phone_number);
        setLocation(waitee.location);
        setBehalfId(waitee.on_behalf_name);
        setAddisionalNotes(waitee.additional_notes)
        setAddWaiteeVisible(true);
        // setCurrentView(COMPONENTS.houseInfo)
    }

    const handleFormSubmitAction = () => {
        if (!selectedWaitee) {
            createWaitee();
        } else {
            updateWaitee();
        }
    }

    const genTableData = () => {
        const rows = waitlist.map((waitee: any, index) => [
            waitee.name,
            waitee.email,
            waitee.location,
            waitee.on_behalf_name,
            <Button type="sm" onClick={() => handleWaiteeSelection(waitee)}>View Info</Button>
        ]);
        const columns = ["Name", "Phone and E-Mail", "Location of Interest", "Inquiry on behalf of", "Action"];


        return { columns: columns, rows: rows };
    }

    // Init Data
    useEffect(() => {
        fetchWaitList();
    }, [])

    const main = () => {
        return <>  <Loader loading={loading} />
            <MainHeader
                title="Wait List"
                info="List of all the Requests from people intrested in living as a tenant."
                buttons={headerButtons()}
            />
            <Container>
                {filterVisible &&
                    <FilterWrapper>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <FilterLabel>Search by name</FilterLabel>
                                    <Input icon={SearchIcon} placeholder="Type name" />
                                </div>
                                <div className="col-md-4">
                                    <FilterLabel>Filetr by Location of Interest</FilterLabel>
                                    <Input icon={SearchIcon} placeholder="Type name or Pin" />
                                </div>
                                <div className="col-md-4 text-center">
                                    <FilterLabel>&nbsp;</FilterLabel>
                                    <Button type="outlined">Clear All Filter</Button>
                                </div>
                            </div>
                        </div>
                    </FilterWrapper>
                }
                <Table data={genTableData()} />
            </Container>
            { addWaiteeVisible &&
                <SlideSheet loading={slideSheetLoading} title={formTitle} maxWidth="437px" onClose={() => setAddWaiteeVisible(false)} action={{ icon: EditIcon, buttonType: "circle", callback: () => handleFormSubmitAction() }} bordered={false}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-4 mt-2">
                                <Input value={name} label="Full Name" placeholder="Full Name" onChange={(e: any) => setName(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={phone} label="Phone Number" placeholder="Phone Number" onChange={(e: any) => setPhone(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={email} label="Email Address" placeholder="Email Address" onChange={(e: any) => setEmail(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={location} label="Area/Location" placeholder="Area/Location" onChange={(e: any) => setLocation(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={behalfId} label="On Behalf of" placeholder="On Behalf of" onChange={(e: any) => setBehalfId(e.currentTarget.value)} />
                            </div>
                            <div className="col-md-12 mb-4">
                                <Input value={addisionalNotes} label="Additional Notes" placeholder="Additional Notes" onChange={(e: any) => setAddisionalNotes(e.currentTarget.value)} />
                            </div>
                        </div>
                        {selectedWaitee &&
                            <div className="row">
                                <div className="col-md-12">
                                    <Button type="sm" onClick={() => alert("Notify")}>Notify</Button>
                                </div>
                            </div>
                        }
                    </div>
                </SlideSheet>
            }
        </>
    }

    const showContent = () => {
        switch (currentView) {
            case COMPONENTS.main:
                return main();
                break;
            case COMPONENTS.houseInfo:
                return <HouseInfo data="" onClose={() => setCurrentView(COMPONENTS.main)} />;
            default:
                break;
        }
    }

    return <>
        <MainWrapper>
            {showContent()}
        </MainWrapper>
    </>
}

export default WaitList;