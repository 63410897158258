import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: inline-block;
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #B2B3BD;
    margin-bottom: 17px
`;

const Value = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 132%;
    color: #11142D;
`;

function Specification(props:any){

    return <>
        <Container>
            <Title>{props.title}</Title>
            <Value>{props.value}</Value>
        </Container>
    </>
}

export default Specification;