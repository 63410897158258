import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import './style.scss'
import { useTheme, Theme } from '../../context/AppContext';
import SidebarTenant from '../../components/sidebar/SidebarTenant';
import SlideSheet from '../../components/slideSheet/SlideSheet';
import TodoInput from '../../components/todo/TodoInput';
import TodoItem from '../../components/todo/TodoItem';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
  import "react-circular-progressbar/dist/styles.css";
import Button from '../../components/buttons/Buttons';
import { eventNames } from 'process';
import Note from '../../components/notes/Note';
import API from '../../platform/api/api';
import Loader from '../../components/common/Loader';

const Container = styled.div<any>`
        background: rgba(${props=> props.themeRGB}, 0.1);
        border-radius: 47px;
        width: 363px;
        padding: 15px 8px;
        text-align: center;
        position: relative;
    `;

    const NewTodoWrapper = styled.div`
        margin-bottom: 21px;
    `;

    const TodosContainer = styled.div`
        margin-bottom: 50px;

        &>div{
            margin-bottom: 6px;
        }
    `;

    const Label = styled.div`
        margin-bottom: 26px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #B2B3BD;
    `;

    const TodosWrapper = styled.div`
        /* display: inline-block; */

        &> div {
            position: unset;
            width: 100%;
        }
    `;

    const OverviewWrapper = styled.div`
        width: 100%;
        padding: 35px 0px;
        display: inline-block;
        padding-bottom: 15px;
    `;

    const TodoAddIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.0001 1.99999V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.00009 9H16.0001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>


    const Card = styled.div`
        box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        padding: 28px;
    `;

    const CardHeader = styled.div`
        border-bottom: 2px solid rgba(199, 199, 199, 0.2);
        margin-bottom: 35px;
        display: flex;
        justify-content: space-between;
    `;

    const CardTitle = styled.div`
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.5px;
        margin-bottom: 26px;
        /* text/active */

        color: #11142D;
    `;

    const CardSecondaryTitile = styled.div`
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        /* identical to box height, or 14px */


        /* Small Title Grey */

        color: #B2B3BD;
        margin-top: 34px;
        margin-bottom: 34px;
    `;

    const MiniCard = styled.div`
        display: flex;
        width: fit-content;
        align-items: center;
    `;

    const MiniCardImage = styled.img`
        border-radius: 6px;
        margin-right: 19px;
        vertical-align: middle;
    `;

    const MiniCardTitle = styled.div`
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 132.2%;
        color: #11142D;
    `;

    const MiniCardInfoWrapper = styled.div`
        
    `;

    const MiniCardDesc = styled.div`
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        margin-top: 3px;
    `;

    const Title = styled.div`
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 105%;
        color: #11142D;
    `;

    const TitleWrapper = styled.div`
        margin-bottom: 42px;
        display: flex;
        justify-content: space-between;
    `;


function Overview(props:any){

    const [status, setStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notesVisible, setNotesVisible] = useState(false);

    // Todo
    const [todoList, setTodoList] = useState([]);
    const [todaysCompletedTodos, setTodaysCompletedTodos] = useState([]);
    const [newTodoText, setNewTodoText] = useState("");
    const [newTodoVisible, setNewTodoVisible] = useState(false);

    const { theme, setTheme } = useTheme();
    
    const [currentView, setCurrentView] = useState<ReactElement>();

    // const MiniCard

    const newTodoEnterPress = (e:KeyboardEvent) => {
        if(e.key == "Enter"){
            createTodo();
            // e.currentTarget.value;
        }
    }

    useEffect(() => {
        fetchTodos();
    },[])

    const fetchTodos = () => {

        setLoading(true);
        API.get('todos/6d63ffde-8cc9-4f20-b1ad-347769f242e0').then(res => {
            setLoading(false);
            setTodoList(res.data.results);
        }).catch( error => {
            
        })
        fetchTodaysTodos();
    }

    const fetchTodaysTodos = () => {
        // setLoading(true);
        API.get('todos/todays_todos/6d63ffde-8cc9-4f20-b1ad-347769f242e0').then(res => {
            // setLoading(false);
            setTodaysCompletedTodos(res.data.results);
        }).catch( error => {
            
        })
    }

    const deleteTodo = (id:string) => {

        setLoading(true);
        API.delete('/todos/delete/' + id).then(res => {
            fetchTodos();
        }).catch( error => {
            
        })
    }

    const createTodo = () => {
        const data = {
            todo_detail: newTodoText,
            employee_id: "6d63ffde-8cc9-4f20-b1ad-347769f242e0"
        }

        setLoading(true);

        API.post(
            'todos/create', 
            data, 
            {headers: {'Content-Type': 'application/json'}})
            .then(res => {
                fetchTodos();
                setNewTodoVisible(false);
                setNewTodoText("");
                // setLoading(false);
            }).catch( error => {
        
            })
    }

    const updateTodo = (id:any, text:string, isCompleted:any) => {
        const data = {
            todo_detail: text,
            is_completed: isCompleted
        }

        setLoading(true);

        API.patch(
            'todos/update/' + id, 
            data, 
            {headers: {'Content-Type': 'application/json'}})
            .then(res => {
                fetchTodos();
                // setLoading(false);
            }).catch( error => {
        
            })
    }

    const noteOne = 
    {
        title: "House Notes",
        desc: "Logan St.",
        image: "/static/icons/note.png",
        notes: [
            {
                id: 1, 
                text: "Desley will be gone for a month, for her annual holiday.", 
                date: "15 Aug 2020 • 19:40PM"
            }
        ]
    }
    const noteTwo = 
    {
        title: "Desley Wallace",
        desc: "Lives in Room 1",
        image: "/static/icons/jan.png",
        notes: [
            {
                id: 1, 
                text: "Desley will be gone for a month, for her annual holiday.", 
                date: "15 Aug 2020 • 19:40PM"
            }
        ]
    }
    const noteThree = 
    {
        title: "Jane Doe",
        desc: "Lives in Room 2",
        image: "/static/icons/jan.png",
        notes: []
    }
    const noteFour = 
    {
        title: "Rashida Jones",
        desc: "Lives in Room 3",
        image: "/static/icons/jan.png",
        notes: []
    }

    const toggleOpenNotes = () => {
        setNotesVisible(!notesVisible);
    }

    // useEffect(() => {
    //     // toggleOpenNotes();
    //     setNotesVisible(props.openNotes);
    //     console.log(props.openNotes);
    // },[props.openNotes])

    return <>
        <Loader loading={loading} />
        <div className="content container g-0 ms-0">
                   <div className="row">
                       <div className="col-md-4">
                        <Card>
                            <TitleWrapper>
                                <Title>To-do</Title>
                                <Button type="circle" icon={TodoAddIcon} onClick={()=> setNewTodoVisible(!newTodoVisible)} />
                            </TitleWrapper>
                            <TodosWrapper>
                                {/* <SlideSheet action={{name:"", callback: ()=> setNewTodoVisible(!newTodoVisible), icon: TodoAddIcon, buttonType: "circle"}} bordered={false} dismissible={false} maxWidth="449px" title="To-do"> */}
                                    {newTodoVisible && 
                                        <NewTodoWrapper>
                                            <TodoInput 
                                                onKeyPress={newTodoEnterPress} 
                                                value={newTodoText} 
                                                onChange={ (e:any) => setNewTodoText(e.currentTarget.value)} 
                                                onDiscard={ () => {setNewTodoVisible(false); setNewTodoText("");}}
                                                />
                                        </NewTodoWrapper>
                                    }
                                    <TodosContainer>
                                            {todoList.filter((i:any)=> i.is_completed.data[0] == 0).map((todo:any, index:number) => 
                                                <TodoItem checked={false} onCheck={(e:any) => updateTodo(todo.todo_id, todo.todo_detail, e.currentTarget.checked)} onDelete={() => deleteTodo(todo.todo_id)} key={index} text={todo.todo_detail} />
                                            )}
                                            {/* <TodoItem/>
                                            <TodoItem/> */}
                                    </TodosContainer>
                                    <Label>Completed Today</Label>
                                    {/* <TodoItem checked={true}/> */}
                                    <TodosContainer>
                                        {todaysCompletedTodos.filter((i:any)=> i.is_completed.data[0] == 1).map((todo:any, index:number) => 
                                            <TodoItem checked={true} onCheck={(e:any) => updateTodo(todo.todo_id, todo.todo_detail, e.currentTarget.checked)} onDelete={() => deleteTodo(todo.todo_id)} key={index} text={todo.todo_detail} />
                                        )}
                                    </TodosContainer>
                                {/* </SlideSheet> */}
                                
                            </TodosWrapper>
                        </Card>
                       </div>
                       <div className="col-md-8">
                            <OverviewWrapper className="overview">
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Vacancies</CardTitle>
                                        <img src="/static/icons/chevron.svg" alt=""/>
                                    </CardHeader>
                                    <div className="circles mb-5 row">
                                        <div className="col-md-4">
                                            <div className="circle mb-4">
                                                <CircularProgressbar styles={buildStyles({
                                                        trailColor: "#E4E4E4",
                                                        strokeLinecap: "butt",
                                                        pathColor: "#6C5DD3",
                                                    })} value={25} text={`${25}%`} 
                                                />
                                                <div className="title">Vacant rooms</div>
                                                <div className="desc">Avaliable Rooms</div>
                                            </div> 
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <div className="circle">
                                                <CircularProgressbar styles={buildStyles({
                                                        trailColor: "#E4E4E4",
                                                        strokeLinecap: "butt",
                                                        pathColor: "#7FBA7A",
                                                    })} value={50} text={`${50}%`} 
                                                />
                                                <div className="title">Waiting List</div>
                                                <div className="desc">Tenats awaiting Approval</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <div className="cricle">
                                                <CircularProgressbar className="mid" styles={buildStyles({
                                                        trailColor: "#E4E4E4",
                                                        strokeLinecap: "butt",
                                                        pathColor: "#7FBA7A",
                                                    })} value={80} text={`${80}%`} 
                                                />
                                                <div className="title">Ocupied </div>
                                                <div className="desc">Rooms rented out</div>
                                            </div>
                                        </div>
                                    </div>
                                    <img  width="100%" src="/map3.png" alt=""/>
                                    <CardSecondaryTitile>Houses in this Location</CardSecondaryTitile>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                                <MiniCard>
                                                    <MiniCardImage src="/static/icons/minicard.png" />
                                                    <MiniCardInfoWrapper>
                                                        <MiniCardTitle>940 Pine Street</MiniCardTitle>
                                                        <MiniCardDesc>Brisbane</MiniCardDesc>
                                                    </MiniCardInfoWrapper>
                                                </MiniCard>
                                        </div>
                                        <div className="col-md-6">
                                                <MiniCard>
                                                    <MiniCardImage src="/static/icons/minicard.png" />
                                                    <MiniCardInfoWrapper>
                                                        <MiniCardTitle>940 Pine Street</MiniCardTitle>
                                                        <MiniCardDesc>Brisbane</MiniCardDesc>
                                                    </MiniCardInfoWrapper>
                                                </MiniCard>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                                <MiniCard>
                                                    <MiniCardImage src="/static/icons/minicard.png" />
                                                    <MiniCardInfoWrapper>
                                                        <MiniCardTitle>940 Pine Street</MiniCardTitle>
                                                        <MiniCardDesc>Brisbane</MiniCardDesc>
                                                    </MiniCardInfoWrapper>
                                                </MiniCard>
                                        </div>
                                        <div className="col-md-6">
                                                <MiniCard>
                                                    <MiniCardImage src="/static/icons/minicard.png" />
                                                    <MiniCardInfoWrapper>
                                                        <MiniCardTitle>940 Pine Street</MiniCardTitle>
                                                        <MiniCardDesc>Brisbane</MiniCardDesc>
                                                    </MiniCardInfoWrapper>
                                                </MiniCard>
                                        </div>
                                    </div>
                                    <div className="button-wrapper mt-5 text-center mb-3">
                                        <Button type="outlined">See all houses from this location</Button>
                                    </div>
                                </Card>
                            </OverviewWrapper>
                            <OverviewWrapper className="overview">
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Waitlist</CardTitle>
                                        <img src="/static/icons/chevron.svg" alt=""/>
                                    </CardHeader>
                                    <div className="circles mb-5 row">
                                        <div className="col-md-4 mb-4">
                                            <div className="circle">
                                                <CircularProgressbar styles={buildStyles({
                                                        trailColor: "#E4E4E4",
                                                        strokeLinecap: "butt",
                                                        pathColor: "#6C5DD3",
                                                    })} value={25} text={`${25}%`} 
                                                />
                                                <div className="title">Vacant rooms</div>
                                                <div className="desc">Avaliable Rooms</div>
                                            </div> 
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <div className="circle">
                                                <CircularProgressbar styles={buildStyles({
                                                        trailColor: "#E4E4E4",
                                                        strokeLinecap: "butt",
                                                        pathColor: "#7FBA7A",
                                                    })} value={50} text={`${50}%`} 
                                                />
                                                <div className="title">Waiting List</div>
                                                <div className="desc">Tenats awaiting Approval</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <div className="cricle">
                                                <CircularProgressbar className="mid" styles={buildStyles({
                                                        trailColor: "#E4E4E4",
                                                        strokeLinecap: "butt",
                                                        pathColor: "#7FBA7A",
                                                    })} value={80} text={`${80}%`} 
                                                />
                                                <div className="title">Ocupied </div>
                                                <div className="desc">Rooms rented out</div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </OverviewWrapper>
                       </div>
                   </div>
                
                   { props.openNotes && 
                <SlideSheet key={props.openNotes} bordered={false} dismissible={false} maxWidth="449px" title="Notes">
                    <Note note={noteOne} />
                    <Note note={noteTwo} />
                    <Note note={noteThree} />
                    <Note note={noteFour} />
                </SlideSheet>
            }
            </div>

    </>
}

export default Overview;