import React, { useState } from 'react';
import styled from 'styled-components';
// import './style.scss'
import { useTheme, Theme } from '../../context/AppContext';
import MainHeader from '../../components/common/MainHeader';
import Button from '../../components/buttons/Buttons';
import Table from '../../components/common/Table';
import TenantInfoMiniCard from '../../components/common/TenantInfoMiniCard';
import InfoCard from '../../components/houseInfo/components/InfoCard';
import Specification from '../../components/houseInfo/components/Specification';
import ImageViewer from '../../components/imageViewer/index';
import SlideSheet from '../../components/slideSheet/SlideSheet';
import TenantInfo from '../tenant/TenantInfo';
import HouseInfoNotes from './HouseInfoNotes';

type props = {
    onClose: Function,
    data: any
}

const Container = styled.div`
    padding: 0px 43px;
`;

const SpecificationsWrapper = styled.div`
    padding-left: 43px;
`;

const MainWrapper = styled.div`
    flex-wrap: wrap;
    width: 100%;
`;

const Line = styled.hr`
    background: #E4E4E4;
    margin-top: 57px;
    margin-bottom: 37px;
`;

const DetailTitle = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #B2B3BD;
    max-width: 277px;
    display: inline-block;
    margin-bottom:31px;
`;

const List = styled.ul`
    padding-left: 17px;
`;

const SmallText = styled.span`
    font-size: 15px;
`;

const AddIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.0001 1.99999V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.00009 9H16.0001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>

const SpecificationsContainer = styled.div`
        margin: 52px 0;
        width: 100%;
    `;

function HouseInfo(props: props) {

    const [selected, setSelected] = useState("");
    const [status, setStatus] = useState(false);
    const { theme, setTheme } = useTheme();
    const [imageViewerVisible, setImageViewerVisible] = useState(false);
    const [tenantInfoVisible, setTenantInfoVisible] = useState(false);
    const [notesVisible, setNotesVisible] = useState(false);

    const headerButtons = () => {
        return [
            {
                name: "Add A Note",
                type: "",
                icon: AddIcon,
                onClick: () => setNotesVisible(true)
            },
            {
                name: "Close",
                type: "",
                icon: "",
                onClick: () => props.onClose()
            }

        ]
    }

    return <>
        <MainWrapper>
            <MainHeader
                title="House Info"
                info="See the list of all the houses, info related to it and edit the tenants."
                buttons={headerButtons()}
            />
            <Container>
                <div className="row d-block-md g-0 w-100">
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <InfoCard
                            image="/house.png"
                            imgButtonText="See House Features"
                            title={props.data.house_address}
                            detailTitle="House Features"
                            details={<ul style={{ paddingLeft: '15px' }}>
                                <li>{props.data.house_features}</li>
                            </ul>}
                            imageButtonClick={() => setImageViewerVisible(!imageViewerVisible)}
                        />
                    </div>
                    <div className="col-md-8">
                        <SpecificationsWrapper>
                            <SpecificationsContainer>
                                <div className="row g-0 justify-content-between">
                                    <div className="col-md-3">
                                        <Specification title="House" value={<><div>{props.data.house_name} </div><div><SmallText>4 Bed + OOA</SmallText></div></>} />
                                    </div>
                                    <div className="col-md-3">
                                        <Specification title="Build Date" value={new Date(props.data.house_build).toLocaleDateString('en-US', { year: 'numeric', month: 'short' })} />
                                    </div>
                                    <div className="col-md-3">
                                        <Specification title="Enrolment Date" value={new Date(props.data.house_enrolment).toLocaleDateString('en-US', { year: 'numeric', month: 'short' })} />
                                    </div>
                                </div>
                                <div className="row g-0 mt-5 justify-content-between">
                                    <div className="col-md-3">
                                        <Specification title="SIL Provider" value="Accommodation and Care Solutions" />
                                    </div>
                                    <div className="col-md-3">
                                        <Specification title="SIL Provider Contact" value="(07) 38866320" />
                                    </div>
                                    <div className="col-md-3">
                                        <Specification title="Vacancy" value={parseInt(props.data.room) - parseInt(props.data.room_reserved)} />
                                    </div>
                                </div>
                            </SpecificationsContainer>
                            <Line />
                            <DetailTitle>Tenants Info</DetailTitle>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <TenantInfoMiniCard onClick={() => setTenantInfoVisible(true)} bg="rgba(1, 203, 57, 0.83)" title="Desly Wallace" desc="NDIS No. 9827382082" image="/static/icons/jan.png" />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <TenantInfoMiniCard bg="#F2C94C" title="John Newman" desc="Pending" image="/static/icons/newman.png" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <TenantInfoMiniCard bg="rgba(1, 203, 57, 0.83)" title="Bob Jones" desc="NDIS No. 9827382082" image="/static/icons/newman.png" />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <TenantInfoMiniCard bg="#DE6E60" title="Desly Wallace" desc="NDIS No. 9827382082" image="/static/icons/jan.png" />
                                </div>
                            </div>
                        </SpecificationsWrapper>
                    </div>
                </div>
                {imageViewerVisible &&
                    <ImageViewer onClose={() => setImageViewerVisible(!imageViewerVisible)} />
                }

            </Container>
            {tenantInfoVisible &&
                <SlideSheet maxWidth="449px" action={{ name: "", buttonType: "circle", icon: AddIcon }} title="Tenant Info" onClose={() => setTenantInfoVisible(false)}>
                    <TenantInfo title="BING" desc="FD" image="" />
                </SlideSheet>
            }
            {notesVisible &&
                <HouseInfoNotes houseImage="/static/icons/note.png" houseName={props.data.house_name} houseAddress={props.data.house_address} houseId={props.data.house_id} onClose={() => setNotesVisible(false)} />
            }
        </MainWrapper>
    </>
}

export default HouseInfo;