import React, { useEffect, useState } from 'react';
import styled from 'styled-components'

const ChevronIcon = <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7L6.925 1.075L12.85 7" stroke="#B2B3BD" strokeWidth="1.71212" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const MainWrapper = styled.div`

`;

const Header = styled.div`
    margin-bottom: 10px;
`;

const TitleWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #B2B3BD;
`;

const ContentWrapper = styled.div`
    &> div{
        margin-bottom: 14px;
    }
`;

const TH = styled.th`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 132.2%;
    color: #11142D;
    opacity: 0.6;
    padding: 10px 0;
`;

const TD = styled.td`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 132.2%;
    text-align: right;
    color: #11142D;
    padding: 10px 0;
`;

const Table = styled.table`
    width: 100%;
`;

const IconWrapper = styled.div<any>`
    transform: rotate(${ props => props.status ? 0 : 180 }deg);
`;

type props = {
    title: string,
    status?: boolean,
    data?: any,
    children?: any
}

export default function Collapse(props: props) {
    const [status, setStatus] = useState(props.status || false);

    return <>
        <MainWrapper>
            <Header onClick={() => setStatus(!status)}>
                <TitleWrapper>
                    <Title>{ props.title }</Title>
                    <IconWrapper status={status}>
                        {ChevronIcon}
                    </IconWrapper>
                </TitleWrapper>
            </Header>
            { status && 
                <ContentWrapper>
                    <Table>
                        <tbody>
                            {props.data && props.data.map((i:any) => {
                                return <tr><TH>{ i.name }</TH><TD>{ i.value }</TD></tr>
                            }) }
                        </tbody>
                    </Table>
                    {props.children}
                </ContentWrapper>
            }
        </MainWrapper>
    </>

}



