import React, { useState } from 'react';
import styled from 'styled-components'
import './style.scss'
import { useTheme, Theme, Color } from '../../context/AppContext';
import Button from '../../components/buttons/Buttons';

const HeaderWrapper = styled.div<any>`
    background-color: #fff;
`;

const CollapseButton = styled(Button)<any>`
    position: absolute;
    display: block;
    top: 130px;
    right: 11px;
    margin-top: -32px;
    z-index: 100;
    border: 1px solid ${props => props.theme.hex};
    background-color: #fff;
`;

const Title = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 105%;
    color: #11142D;
    letter-spacing: -1px;
    display: inline-block;
`;

const CloseText = styled.span<any>`
    margin-right: 27px;
    color: ${props => props.color};
`;

function FullSheet(props:any){
    const { theme, setTheme } = useTheme();

    return <>
        <div className="full-sheet-box">
            <HeaderWrapper className="full-sheet-wrapper" bg={theme.rgb}>
                <header className="content-header">
                <div className="title-box">
                    <Title>{ props.title }</Title>
                    <span className="action-group">
                        <CloseText color={theme.hex}>Close</CloseText>
                        <Button onClick={props.onClose} type="circle" icon={<svg width="12px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>}>
                            </Button>
                    </span>
                </div>
            </header>
                
            </HeaderWrapper>

            <div className="content-container">
                { props.children }
            </div>
     
        </div>
    </>
}

export default FullSheet;