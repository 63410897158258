import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import Note from '../../components/notes/Note';
import SlideSheet from '../../components/slideSheet/SlideSheet';
import API from '../../platform/api/api';
import Loader from '../../components/common/Loader';
import { useTheme, Theme, useAppContext } from '../../context/AppContext';

const noteOne =
{
    title: "House Notes",
    desc: "Logan St.",
    image: "/static/icons/note.png",
    notes: [
        {
            id: 1,
            text: "Desley will be gone for a month, for her annual holiday.",
            date: "15 Aug 2020 • 19:40PM"
        }
    ]
}
const noteTwo =
{
    title: "Desley Wallace",
    desc: "Lives in Room 1",
    image: "/static/icons/jan.png",
    notes: [
        {
            id: 1,
            text: "Desley will be gone for a month, for her annual holiday.",
            date: "15 Aug 2020 • 19:40PM"
        }
    ]
}
const noteThree =
{
    title: "Jane Doe",
    desc: "Lives in Room 2",
    image: "/static/icons/jan.png",
    notes: []
}
const noteFour =
{
    title: "Rashida Jones",
    desc: "Lives in Room 3",
    image: "/static/icons/jan.png",
    notes: []
}

type props = {
    houseId: string,
    houseName: string,
    houseAddress: string,
    onClose: Function,
    houseImage: string
}

const LoaderWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index:1000;
`;

export default function HouseInfoNotes(props: props) {
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState([]);
    const [newNoteText, setNewNoteText] = useState("");
    const [employees, setEmployees] = useState([]);
    const { user } = useAppContext();

    useEffect(() => {
        fetchNotes();
    },[])

    const fetchNotes = () => {
        setLoading(true);
        API.get('notes/' + props.houseId).then(res => {
            setLoading(false);
            setNotes(res.data.results);
            prepairNotes(res.data.results);
        }).catch( error => {
            
        })
    }

    const formatDate = (date:any)=> {
        const d = new Date(date);
        
        const time = d.toLocaleString("en-US", {hour: '2-digit', hour12: true, minute: "2-digit"});
        const datef = d.toLocaleString("en-US",{day: "numeric",month: "short", year: "numeric"});
        return datef + " • " + time;
    }

    const prepairNotes = (data:any) => {
        let empList : any = [];
        data.forEach((i:any) => !empList.includes(i.employee_id) ? empList.push(i.employee_id) : false);

       let prepairedData =  empList.map((i:any) => (
                {id: i, 
                    notes: data.filter(
                        (k:any) => k.employee_id == i
                        ).map((n:any) => {
                            return {id: n.notes_id, text: n.notes_message, date: formatDate(n.created_at)};
                         })
                    })
            );

        // prepairedData = prepairedData.map( (i:any) => {
        //     i.notes = i.notes.map((n:any) => {
        //        return {id: n.notes_id, text: n.notes_message, date: n.created_at};
        //     })
        //     console.log( {id: i.employee.id, notes: i.notes});
        // });
        // alert("boom");
        console.log(prepairedData);
        setEmployees(prepairedData);
    }

    const deleteNote = (id:string) => {

        setLoading(true);
        API.delete('/notes/delete/' + id).then(res => {
            fetchNotes();
        }).catch( error => {
            
        })
    }

    const createNote = () => {
        const data = {
            note_message: newNoteText,
            employee_id: user.employee_id,
            house_id: props.houseId
        
        }

        setLoading(true);

        API.post(
            'notes/create', 
            data, 
            {headers: {'Content-Type': 'application/json'}})
            .then(res => {
                fetchNotes();
                setNewNoteText("");
                // setNewTodoText("");
                // setLoading(false);
            }).catch( error => {
        
            })
    }

    const Loading = <Loader loading={true} position="absolute" />;

    return <>
        
        <SlideSheet loading={loading} bordered={false} onClose={props.onClose} dismissible={true} maxWidth="449px" title="Notes">
            
            {/* { employees.map((employee:any, index:number) => 
                <Note 
                    note={
                            {
                                title: "House Notes", 
                                desc: "Logan St.", 
                                image: "/static/icons/jan.png",
                                notes: employee.notes
                            }
                        } 
                />
            ) } */}
                <Note 
                    title={props.houseName}
                    desc={props.houseAddress} 
                    image={props.houseImage}
                    add={true}
                    onChange={ (e:any) => setNewNoteText(e.currentTarget.value)}
                    onEnterPress={() => createNote()}
                />
            { notes.map((note:any, index:number) => 
                <Note 
                    title="EMP"
                    image="/static/icons/jan.png"
                    text={note.notes_message}
                    onDelete={() => deleteNote(note.notes_id)}
                    date={formatDate(note.created_at)}
                />
            )}
            {/* <Note note={noteOne} />
            <Note note={noteTwo} />
            <Note note={noteThree} />
            <Note note={noteFour} /> */}
        </SlideSheet>
    </>

}