import React, { useState } from 'react';
import styled from 'styled-components';
// import './style.scss'
import { useTheme, Theme } from '../../context/AppContext';

const Container = styled.div<any>`
    border: 1px solid ${props => props.theme.hex};
    box-sizing: border-box;
    border-radius: 6px;
    padding: 15px 32px 15px 24px;
`;

const TextArea = styled.textarea`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #11142D;
    resize: none;
    outline: none;
    width: 100%;
    border: none;
    margin-bottom: 4px;
`;

const ActionLink = styled.span<any>`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #11142D;
    opacity: 0.6;
    float: ${props=> props.float};
    padding-left: 2px;
    cursor: pointer;
`;

const ActionsWrapper = styled.div`
    margin-bottom: 15px;
`;

function TodoInput(props:any){

    const [selected, setSelected] = useState("");
    const [status, setStatus] = useState(false);
    const { theme, setTheme } = useTheme();

    return <>
        <Container theme={theme}>
            <TextArea defaultValue={props.value} onChange={props.onChange} placeholder={props.placeholder ? props.placeholder : "What are you planing?"} onKeyPress={props.onKeyPress}></TextArea>
            <ActionsWrapper>
                <ActionLink float="left">Press Enter to save</ActionLink>
                <ActionLink onClick={props.onDiscard} float="right">Discard</ActionLink>
            </ActionsWrapper>
        </Container>
    </>
}

export default TodoInput;