import React, { useEffect, useState } from 'react';
import styled from 'styled-components'

const Wrapper = styled.div<any>`
    position: ${props=> props.position ? props.position : "fixed"};
    left: 0;
    top: 0;
    background-color: rgb(232 232 232 / 37%);
    height: 100vh;
    z-index: 90000;
    backdrop-filter: blur(7px);
    width: 100%;
`;

const LoadingBar = styled.div`
    margin: 0 auto;
    position: relative;
    top: 50%;
`;

interface props {
    loading: boolean, 
    position?: string,
  }

export default function Loader(props: props) {

    return <>
        { props.loading && 
            <Wrapper position={props.position}>
                <LoadingBar className="min-loader"></LoadingBar>
            </Wrapper>
        }
    </>

}