import { createContext, useContext } from 'react';
import {IContextBase} from "../platform/interfaces/context-base";
import {RoleEnum} from '../platform/enums/role';

export type Color = {
    hex: string,
    rgb: string,
    secondary: {hex: string, rgb: string},
    role: number
}
// {hex: '#41BCFE', rgb: '65 188 254'}
// export enum Theme {
//     Sales = ,
//     Tenant = '#6C5DD3',
//     Build = '#32CB84',
//     Investor = '#FF8449',
//     SalesRGB = '65 188 254',
//     TenantRGB = '108 93 211',
//     BuildRGB = '50 203 132',
//     InvestorRGB = '255 132 73',
// }
// export enum Theme{
//     Sales = <Colo,
//     Tenant = '#6C5DD3',
//     Build = '#32CB84',
//     Investor = '#FF8449',
//     SalesRGB = '65 188 254',
//     TenantRGB = '108 93 211',
//     BuildRGB = '50 203 132',
//     InvestorRGB = '255 132 73',
// }

export const Theme: ThemeColors = {
    Sales: {hex: '#41BCFE', rgb: '65, 188, 254', secondary: {hex: "#32CB84", rgb: "127, 186, 122"}, role: RoleEnum.Sales },
    Tenant: {hex: '#6C5DD3', rgb: '108, 93, 211', secondary: {hex: "#32CB84", rgb: "127, 186, 122"}, role: RoleEnum.Tenant},
    Build: {hex: '#32CB84', rgb: '50, 203, 132', secondary: {hex: "#32CB84", rgb: "127, 186, 122"}, role: RoleEnum.Build},
    Investor: {hex: '#FF8449', rgb: '255, 132, 73', secondary: {hex: "#32CB84", rgb: "127, 186, 122"}, role: RoleEnum.Investor}
}

export type ThemeColors = {
    Sales: Color,
    Tenant: Color,
    Build: Color,
    Investor: Color
}

export type ThemeContextType = {
    theme: Color;
    setTheme: (Theme: Color) => void;
}

export const ThemeContext = createContext<ThemeContextType>(
    { 
        theme: Theme.Sales,
        setTheme: theme => console.warn('no theme provider')
});


export type User = {
    employee_id: number,
    email: string,
    profile_image: string,
    contact: string,
    address: string,
    role_id: number,
    area: string,
    created_at: string,
    updated_at: string,
}

export type Role  = {
    role_id: string,
    role_description: string,
    role_name: string
  }

  export type appContextType = {
    user: any,
    setUser: Function
}



export const AppContext = createContext<appContextType>({
    user: null,
    setUser: () => null
});

export const useAppContext = () => useContext(AppContext);

export const useTheme = () => useContext(ThemeContext);