import React, { useState } from 'react';
import styled from 'styled-components'
import './style.scss'
import { useTheme, Theme, Color } from '../../context/AppContext';
import Button from '../../components/buttons/Buttons';
import Loader from '../../components/common/Loader';

const Wrapper = styled.div<any>`
    max-width: ${props => props.maxWidth};
    overflow: ${props => props.loading ? "hidden" : "unset"};
    /* left: 302px; */
`;

const HeaderWrapper = styled.div<any>`
    background-color: #fff;
    filter: blur(${props => props.loading ? 8 : 0}px);
    /* padding: 0 43px; */
`;

const CollapseButton = styled(Button)<any>`
    position: absolute;
    display: block;
    top: 130px;
    right: 11px;
    margin-top: -32px;
    z-index: 1000;
    border: 1px solid ${props=>props.theme.hex};
    background-color: #fff;
`;

const Title = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 105%;
    color: #11142D;
    letter-spacing: -1px;
    display: inline-block;
`;

const BackgroundOverlay = styled.div`
    background: rgba(68, 68, 68, 0.8);
    position: fixed;
    /* left: 302px; */
    width: 100%;
    height: 100vh;
    top:0;
    z-index:10;
`;

const Header = styled.header<any>`
    border-bottom: ${props => props.bordered == false ? "none !important" : "2px solid rgb(199, 199, 199, 0.1)"};
    padding: 0 43px;
`;

const ContentContainer = styled.div`
    padding: 0 43px;
    overflow: scroll;
    height: 100%;
    padding-bottom: 100px;
`

function SlideSheet(props:any){
    const { theme, setTheme } = useTheme();

    return <>
        <BackgroundOverlay>
            <Wrapper loading={props.loading} {...props} className="slide-sheet-box">
                {props.loading && 
                    <Loader loading={true} position="absolute" />
                }
                {/* {!props.loading && <> */}
                    <HeaderWrapper loading={props.loading} className="slide-sheet-wrapper" bg={theme.rgb}>
                    <Header bordered={props.bordered} className="content-header">
                    <div className="title-box" style={{padding: '33px 0'}}>
                        <Title>{ props.title }</Title>
                        { props.action && 
                        <span style={{float:'right'}} className="action-group">
                            <Button type={props.action.buttonType} icon={props.action.icon} onClick={props.action.callback}>{props.action.name}</Button>
                        </span>
                        }
                        </div>
                    </Header>
                    {props.dismissible != false && 
                        <CollapseButton theme={theme} onClick={props.onClose} type="circle" size="small" icon={
                        <svg width="8px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path fill={theme.hex}
                            d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                            ></path>
                        </svg>} />
                    }
                </HeaderWrapper>

                <ContentContainer className="content-container">
                    { props.children }
                </ContentContainer>
                {/* </>} */}
                
        
            </Wrapper>
        </BackgroundOverlay>
    </>
}

export default SlideSheet;