import React, { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import { useTheme, Theme } from '../../context/AppContext';
import SlideSheet from '../slideSheet/SlideSheet';
import FullSheet from '../../components/fullSheet/FullSheet';
import Breadcrumbs from '../breadcrumbs/index';
import PlanCard from '../planCard/PlanCard';
import './style.scss'
import PlanInfo from '../houseInfo/components/planInfo/PlanInfo';
import { Link, Redirect, useHistory } from "react-router-dom";
import Button from '../buttons/Buttons';
import API, {clAPI} from '../../platform/api/api';
import Loader from '../common/Loader';

interface Data {
    id: number, 
    text: string, 
    content?: any,
    action?: {name: string, callback: Function}
  }

function CreatePackage(props:any){
    const history = useHistory();
    const { theme, setTheme } = useTheme();
    const [selectedPlanCard, setSelectedPlanCard] : any = useState(null);
    const [createdPackageVisible, setCreatedPackageVisible] = useState(false);
    const [packages, setPackages] : any = useState(null);
    const [housePlans, setHousePlans] : any = useState([]);

    const [shortlistedLands, setShortlistedLands] : any = useState([]);
    const [selectedLand, setSelectedLand] : any = useState(null);
    const [isPackageCreated, SetIsPackageCreated] = useState(false);

    const [loading, setLoading] = useState(false);

    const getHousePlan = (id:number)  => {
        return housePlans.find( (i:any) => i.house_plan_id == id);
    }
    // const actions = [
    //     {
    //         name:"Select Land", 
    //         callback: () => setActiveBreadcrumb(breadcrumbs[1])
    //     },
    //     {
    //         name:"Preview", 
    //         callback: () => setActiveBreadcrumb(breadcrumbs[2])
    //     }
    // ]

    const fetchShortlistedPlan = () => {
        setLoading(true);
        API.get('land/shortlisted').then(res => {

            setShortlistedLands(res.data.results);
            setLoading(false);

            }).catch( error => {
                if(error.status==401){
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('user');
                    history.push('/');
                }
            })
    }

    const createPackage = () => {
        setLoading(true);
        const data = {
            "house_plan_id": selectedPlanCard.house_plan_id,
            "land_id": selectedLand.land_id,
        };
        API.post('package/create', data).then(res => {

            setLoading(false);
            if(res.status = 200){
                setActiveBreadcrumb(findBreadcrumb(4));
                SetIsPackageCreated(true);
                // resetPackage();
            };

            }).catch( error => {
                if(error.status==401){
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('user');
                    history.push('/');
                }
            })
    }

    useEffect(()=>{
            setLoading(true);

            API.get('package').then(res => {

            setPackages(res.data.results);

            }).catch( error => {
                if(error.status==401){
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('user');
                    history.push('/');
                }
            })
            API.get('house/house_plans').then(res => {

            setHousePlans(res.data.results);
            setLoading(false);

            }).catch( error => {
                if(error.status==401){
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('user');
                    history.push('/');
                }
            })
        
    },[])

    const findBreadcrumb = (id:number) => {
       return breadcrumbs.find((i:any) => i.id == id)
    }

    const action = (name:string, breadcrumbId: number, callback:any ) => {
        // console.log(selectedPlanCard);
        return {
            name: name, 
            callback: () => {
                setActiveBreadcrumb(findBreadcrumb(breadcrumbId));
                callback();
            }
        }
    }

    const handleLandSelection = (e:any, land:any) => {
        const checked = e.currentTarget.checked;
        
        if(checked){
            setSelectedLand(land);
        } else {
            setSelectedLand(null);
        }
    }

    const ChoosePacakge = <div className="row">
                        {selectedPlanCard && selectedPlanCard.house_plan_id}
                        {housePlans && housePlans.map((plan:any) => 
                            <div key={plan.house_plan_id} className="col-md-3-5 mb-4">
                                <PlanCard 
                                    onClick={() => setSelectedPlanCard(plan)} 
                                    selected={JSON.stringify(selectedPlanCard) == JSON.stringify(plan)} 
                                    title={getHousePlan(plan.house_plan_id).house_plan} 
                                    description={getHousePlan(plan.house_plan_id).description}
                                    rooms={getHousePlan(plan.house_plan_id).rooms}
                                    gaurage={getHousePlan(plan.house_plan_id).gaurage}
                                    baths={getHousePlan(plan.house_plan_id).baths}
                                    img="/plane.png"></PlanCard>
                            </div>      
                        )}
                        
                    </div>
    const ShortList = <table className="table table-responsive table-create-package-one">
                        <thead>
                            <tr>
                            <th></th>
                            <th scope="col">Location</th>
                            <th scope="col">Area(m2)</th>
                            <th scope="col">Owner</th>
                            </tr>
                        </thead>
                        <tbody>
                            {shortlistedLands.map((land:any) => 
                                <tr key={land.land_id}>
                                    <td><input checked={(selectedLand  && selectedLand.land_id == land.land_id) ? true : false} onChange={(e:any) => handleLandSelection(e, land)} className="checkbox form-check-input" type="checkbox"/></td>
                                    <td>{ land.land_address }</td>
                                    <td>{ land.land_area }</td>
                                    <td>{ land.owner_info }</td>
                                </tr>
                            )}
                        </tbody>
                        </table>

    const Preview = <><table className="table table-responsive table-create-package-preview">
                        <tbody>
                            <tr>
                                <th scope="col">Plan Info</th>
                                <td>
                                    <div>Plan</div>
                                    <div>Description</div>
                                    </td>
                                <td>
                                    { selectedPlanCard && 
                                        <>
                                            <div>{ selectedPlanCard.house_plan }</div>
                                            <div>{ selectedPlanCard.description }</div>
                                        </>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th scope="col">Land Info</th>
                                <td>
                                    <div>Address</div>
                                    <div>Owner</div>
                                    <div>Lat</div>
                                    <div>Lng</div>
                                    <div>Area</div>
                                    <div>Features</div>
                                </td>
                                <td>
                                    { selectedLand && 
                                        <>
                                            <div>{ selectedLand.land_address }</div>
                                            <div>{ selectedLand.owner_info }</div>
                                            <div>{ selectedLand.land_lat }</div>
                                            <div>{ selectedLand.land_lng }</div>
                                            <div>{ selectedLand.land_area }</div>
                                            <div>{ selectedLand.land_features }</div>
                                        </>
                                    }
                                </td>
                            </tr>
                            {/* <tr>
                                <th scope="col">Other Costs and Investment Info</th>
                                <td>
                                    <div>Amazon Echo</div>
                                    <div>Asset Management</div>
                                </td>
                                <td>
                                    <div>$200</div>
                                    <div>$8,000</div>
                                </td>
                            </tr> */}
                            {/* <tr>
                                <th scope="col">Total Package</th>
                                <td>$716,000</td>
                            </tr> */}
                        </tbody>
                        </table>
                        <div className="mt-5"><Button onClick={createPackage}>Create Package</Button></div>
                        </>

    const packageCreatedView = <>
        
        { isPackageCreated &&
            <>
             <div className="mb-3">Success! The package has been created successfully.</div>
            <div className="mb-3 mt-5">You may close this window now.</div>
            </>
        }
        {/* <Button onClick={() => onPackageCreated()}>View Package</Button> */}
    </>

    const onPackageCreated = () => {
        resetPackage();
        history.push("/live");
    }

    const resetPackage = () => {
        // return false;
        setSelectedLand(null);
        setSelectedPlanCard(null);
        setHousePlans([]);
        setShortlistedLands([]);
    }

    const breadcrumbs:any = [
        {
            id:1, 
            text:"Choose Plan", 
            content: ChoosePacakge, 
            action: action("Select Plan", 2, fetchShortlistedPlan),
            onClick: () => {
                // if(selectedPlanCard == null){
                //     return false;
                // }
                setActiveBreadcrumb(findBreadcrumb(1));
                // fetchShortlistedPlan();
            }
        }, 
        {
            id:2, 
            text:"Select Shortlisted Land", 
            content: ShortList, 
            action: action("Preview", 3, () => console.log(selectedPlanCard)),
            onClick: () => setActiveBreadcrumb(findBreadcrumb(2))
        }, 
        {
            id:3, 
            text:"Preview", 
            content: Preview, 
            action: undefined,
            onClick: () => setActiveBreadcrumb(findBreadcrumb(4))
        }, 
        {
            id:4, 
            text: "Package Created", 
            content: packageCreatedView,
            action: undefined,
            onClick: () => false
        }, 
    ];

        
    const [activeBreadcrumb, setActiveBreadcrumb] = useState<Data>(breadcrumbs[0]);
    


    return <>
        <SlideSheet 
            key="fjs"
            title="Create Package" 
            onClose={() => {props.onClose(); resetPackage(); SetIsPackageCreated(false)}} 
            action={activeBreadcrumb.action}>

            <Loader loading={loading}/>
            <div className="mt-4">
                <Breadcrumbs clicks={true} selected={activeBreadcrumb.id} data={breadcrumbs} />
            </div>
        </SlideSheet>
            { createdPackageVisible && 
                <FullSheet onClose={() => setCreatedPackageVisible(false)} title="Generated Package">
                    <PlanInfo/>
                </FullSheet>
            }
    </>
}

export default CreatePackage;