import React, { ReactElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import './style.scss'
import { useTheme, Theme } from '../../context/AppContext';
import SidebarTenant from '../../components/sidebar/SidebarTenant';
import SlideSheet from '../../components/slideSheet/SlideSheet';
import TodoInput from '../../components/todo/TodoInput';
import TodoItem from '../../components/todo/TodoItem';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
  import "react-circular-progressbar/dist/styles.css";
import Button from '../../components/buttons/Buttons';
import { eventNames } from 'process';
import Note from '../../components/notes/Note';
import FullSheet from '../../components/fullSheet/FullSheet';

// Pages
import HouseList from './HouseList';
import Overview from './Overview';
import WaitList from './WaitList';
import FileManager from './FileManager';
import TenantManager from './TenantManager';

import { ReactComponent } from '*.svg';

const Container = styled.div<any>`
        background: rgba(${props=> props.themeRGB}, 0.1);
        border-radius: 47px;
        width: 363px;
        padding: 15px 8px;
        text-align: center;
        position: relative;
    `;

    const NewTodoWrapper = styled.div`
        margin-bottom: 21px;
    `;

    const UnCompletedTodoWrapper = styled.div`
        margin-bottom: 50px;

        &>div{
            margin-bottom: 6px;
        }
    `;

    const Label = styled.div`
        margin-bottom: 26px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #B2B3BD;
    `;

    const TodosWrapper = styled.div`
        /* display: inline-block; */

        &> div {
            position: unset;
            width: 100%;
        }
    `;

    const OverviewWrapper = styled.div`
        width: 100%;
        padding: 35px 0px;
        display: inline-block;
        padding-bottom: 15px;
    `;

    const TodoAddIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.0001 1.99999V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.00009 9H16.0001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>


    const Card = styled.div`
        box-shadow: 0px 7px 21px rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        padding: 28px;
    `;

    const CardHeader = styled.div`
        border-bottom: 2px solid rgba(199, 199, 199, 0.2);
        margin-bottom: 35px;
        display: flex;
        justify-content: space-between;
    `;

    const CardTitle = styled.div`
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.5px;
        margin-bottom: 26px;
        /* text/active */

        color: #11142D;
    `;

    const CardSecondaryTitile = styled.div`
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        /* identical to box height, or 14px */


        /* Small Title Grey */

        color: #B2B3BD;
        margin-top: 34px;
        margin-bottom: 34px;
    `;

    const MiniCard = styled.div`
        display: flex;
        width: fit-content;
        align-items: center;
    `;

    const MiniCardImage = styled.img`
        border-radius: 6px;
        margin-right: 19px;
        vertical-align: middle;
    `;

    const MiniCardTitle = styled.div`
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 132.2%;
        color: #11142D;
    `;

    const MiniCardInfoWrapper = styled.div`
        
    `;

    const MiniCardDesc = styled.div`
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        margin-top: 3px;
    `;

    const Title = styled.div`
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 105%;
        color: #11142D;
    `;

    const TitleWrapper = styled.div`
        margin-bottom: 42px;
        display: flex;
        justify-content: space-between;
    `;

    enum COMPONENTS {
        overview,
        houselist,
        waitlist,
        fileManager,
        tenantsManager
    }


function TenantHome(props:any){

    const [status, setStatus] = useState(false);
    const [notesVisible, setNotesVisible] = useState(false);
    const [newTodoText, setNewTodoText] = useState("");
    const [newTodoVisible, setNewTodoVisible] = useState(false);
    const { theme, setTheme } = useTheme();
    
    const [currentView, setCurrentView] = useState<COMPONENTS>(COMPONENTS.houselist);
    const contentView:ReactElement = <></>;

    // const MiniCard

    useEffect(() => {
        // toggleOpenNotes();
        // setNotesVisible(props.openNotes);
        // console.log(props.openNotes);
    },[contentView])

    const newTodoEnterPress = (e:KeyboardEvent) => {
        if(e.key == "Enter"){
            alert("Todo Task");
        }
    }
    const noteOne = 
    {
        title: "House Notes",
        desc: "Logan St.",
        image: "/static/icons/note.png",
        notes: [
            {
                id: 1, 
                text: "Desley will be gone for a month, for her annual holiday.", 
                date: "15 Aug 2020 • 19:40PM"
            }
        ]
    }
    const noteTwo = 
    {
        title: "Desley Wallace",
        desc: "Lives in Room 1",
        image: "/static/icons/jan.png",
        notes: [
            {
                id: 1, 
                text: "Desley will be gone for a month, for her annual holiday.", 
                date: "15 Aug 2020 • 19:40PM"
            }
        ]
    }
    const noteThree = 
    {
        title: "Jane Doe",
        desc: "Lives in Room 2",
        image: "/static/icons/jan.png",
        notes: []
    }
    const noteFour = 
    {
        title: "Rashida Jones",
        desc: "Lives in Room 3",
        image: "/static/icons/jan.png",
        notes: []
    }

    const sidebarData = () => {
        const overviewIcon = <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.2174 2.02808C17.57 2.02808 17.0452 2.55289 17.0452 3.20027C17.0452 3.84766 17.57 4.37247 18.2174 4.37247C18.8647 4.37247 19.3896 3.84766 19.3896 3.20027C19.3896 2.55289 18.8647 2.02808 18.2174 2.02808ZM15.5452 3.20027C15.5452 1.72446 16.7415 0.528076 18.2174 0.528076C19.6932 0.528076 20.8896 1.72446 20.8896 3.20027C20.8896 4.67609 19.6932 5.87247 18.2174 5.87247C16.7415 5.87247 15.5452 4.67609 15.5452 3.20027ZM15.2625 8.20011C15.59 8.4538 15.6497 8.9249 15.396 9.25233L12.467 13.0326C12.3446 13.1906 12.1643 13.2931 11.966 13.3176C11.7678 13.3421 11.5679 13.2865 11.4108 13.1631L8.59238 10.9491L6.06121 14.2389C5.80863 14.5672 5.33773 14.6285 5.00945 14.3759C4.68116 14.1234 4.61979 13.6525 4.87238 13.3242L7.86552 9.43402C7.98761 9.27533 8.16808 9.17205 8.36675 9.14718C8.56541 9.1223 8.76578 9.17789 8.92323 9.30157L11.7437 11.5171L14.2103 8.33361C14.464 8.00618 14.9351 7.94641 15.2625 8.20011ZM5.87872 1.37012C4.18683 1.37012 2.7599 1.97449 1.76005 3.05302C0.766156 4.12514 0.25 5.60468 0.25 7.26428V15.3467C0.25 17.0025 0.754815 18.4807 1.74558 19.5525C2.74295 20.6316 4.17154 21.2317 5.87872 21.2317H14.4828C16.1737 21.2317 17.6006 20.6303 18.6009 19.554C19.5953 18.484 20.1116 17.0064 20.1116 15.3467V8.30776C20.1116 7.89355 19.7758 7.55776 19.3616 7.55776C18.9473 7.55776 18.6116 7.89355 18.6116 8.30776V15.3467C18.6116 16.6984 18.1942 17.7883 17.5021 18.5329C16.8159 19.2713 15.8034 19.7317 14.4828 19.7317H5.87872C4.53784 19.7317 3.52707 19.27 2.8471 18.5344C2.16052 17.7916 1.75 16.7023 1.75 15.3467V7.26428C1.75 5.91244 2.16748 4.8199 2.86008 4.0728C3.54673 3.33211 4.55916 2.87012 5.87872 2.87012H13.1465C13.5607 2.87012 13.8965 2.53433 13.8965 2.12012C13.8965 1.7059 13.5607 1.37012 13.1465 1.37012H5.87872Z" fill={theme.hex} />
        </svg>
        const houseListIcon = <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.4605 0.407986C10.1896 0.197338 9.81038 0.197338 9.53954 0.407986L0.539545 7.40799C0.356855 7.55008 0.25 7.76856 0.25 8V19C0.25 19.7293 0.539731 20.4288 1.05546 20.9445C1.57118 21.4603 2.27065 21.75 3 21.75H7H13H17C17.7293 21.75 18.4288 21.4603 18.9445 20.9445C19.4603 20.4288 19.75 19.7293 19.75 19V8C19.75 7.76856 19.6431 7.55008 19.4605 7.40799L10.4605 0.407986ZM13.75 20.25H17C17.3315 20.25 17.6495 20.1183 17.8839 19.8839C18.1183 19.6495 18.25 19.3315 18.25 19V8.36681L10 1.95015L1.75 8.36681V19C1.75 19.3315 1.8817 19.6495 2.11612 19.8839C2.35054 20.1183 2.66848 20.25 3 20.25H6.25V11C6.25 10.5858 6.58579 10.25 7 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11V20.25ZM7.75 20.25V11.75H12.25V20.25H7.75Z" fill={theme.hex} />
            </svg>
        const waitlistIcon = <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.19439 5.44444C6.19439 3.40406 7.84845 1.75 9.88883 1.75C11.9292 1.75 13.5833 3.40406 13.5833 5.44444C13.5833 7.48483 11.9292 9.13889 9.88883 9.13889C7.84845 9.13889 6.19439 7.48483 6.19439 5.44444ZM9.88883 0.25C7.02002 0.25 4.69439 2.57563 4.69439 5.44444C4.69439 8.31326 7.02002 10.6389 9.88883 10.6389C12.7576 10.6389 15.0833 8.31326 15.0833 5.44444C15.0833 2.57563 12.7576 0.25 9.88883 0.25ZM5.44444 13.5834C4.06679 13.5834 2.74556 14.1307 1.77142 15.1048C0.79727 16.079 0.25 17.4002 0.25 18.7779V21.0001C0.25 21.4143 0.585786 21.7501 1 21.7501C1.41421 21.7501 1.75 21.4143 1.75 21.0001V18.7779C1.75 17.798 2.13923 16.8583 2.83208 16.1655C3.52492 15.4727 4.46462 15.0834 5.44444 15.0834H14.3333C15.3132 15.0834 16.2529 15.4727 16.9457 16.1655C17.6385 16.8583 18.0278 17.798 18.0278 18.7779V21.0001C18.0278 21.4143 18.3636 21.7501 18.7778 21.7501C19.192 21.7501 19.5278 21.4143 19.5278 21.0001V18.7779C19.5278 17.4002 18.9805 16.079 18.0064 15.1048C17.0322 14.1307 15.711 13.5834 14.3333 13.5834H5.44444Z" fill={theme.hex} />
            </svg>
        const fileManagementIcon = <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3 1.75C2.66848 1.75 2.35054 1.8817 2.11612 2.11612C1.8817 2.35054 1.75 2.66848 1.75 3V17C1.75 17.3315 1.8817 17.6495 2.11612 17.8839C2.35054 18.1183 2.66848 18.25 3 18.25H19C19.3315 18.25 19.6495 18.1183 19.8839 17.8839C20.1183 17.6495 20.25 17.3315 20.25 17V6C20.25 5.66848 20.1183 5.35054 19.8839 5.11612C19.6495 4.8817 19.3315 4.75 19 4.75H10C9.74924 4.75 9.51506 4.62467 9.37596 4.41603L7.59861 1.75H3ZM1.05546 1.05546C1.57118 0.539731 2.27065 0.25 3 0.25H8C8.25076 0.25 8.48494 0.375326 8.62404 0.583975L10.4014 3.25H19C19.7293 3.25 20.4288 3.53973 20.9445 4.05546C21.4603 4.57118 21.75 5.27065 21.75 6V17C21.75 17.7293 21.4603 18.4288 20.9445 18.9445C20.4288 19.4603 19.7293 19.75 19 19.75H3C2.27065 19.75 1.57118 19.4603 1.05546 18.9445C0.539731 18.4288 0.25 17.7293 0.25 17V3C0.25 2.27065 0.539731 1.57118 1.05546 1.05546Z" fill={theme.hex} />
        </svg>

        const notesIcon = <svg width="22" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill={theme.hex} d="M336 64h-88.6c.4-2.6.6-5.3.6-8 0-30.9-25.1-56-56-56s-56 25.1-56 56c0 2.7.2 5.4.6 8H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM192 32c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm160 432c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16h48v20c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12V96h48c8.8 0 16 7.2 16 16z"></path></svg>

        return [
                    {
                        name: "Overview",
                        icon: overviewIcon,
                        onClick: () => setCurrentView(COMPONENTS.overview),
                        active: currentView == COMPONENTS.overview
                    },
                    {
                        name: "House List",
                        icon: houseListIcon,
                        onClick: () => setCurrentView(COMPONENTS.houselist),
                        active: currentView == COMPONENTS.houselist
                    },
                    {
                        name: "Wait List",
                        icon: waitlistIcon,
                        onClick: () => setCurrentView(COMPONENTS.waitlist),
                        active: currentView == COMPONENTS.waitlist
                    },
                    {
                        name: "File Management",
                        icon: fileManagementIcon,
                        onClick: () => setCurrentView(COMPONENTS.fileManager),
                        active: currentView == COMPONENTS.fileManager
                    },
                    {
                        name: "Tenants",
                        icon: notesIcon,
                        onClick: () => setCurrentView(COMPONENTS.tenantsManager)
                    }
                ]
    }

    const showContent = () => {

        switch (currentView) {
                    case COMPONENTS.overview:
                        return <Overview openNotes={notesVisible} />
                        break;
                    case COMPONENTS.houselist:
                        return <HouseList/>
                        break;
                    case COMPONENTS.waitlist:
                        return <WaitList/>
                        break;
                    case COMPONENTS.fileManager:
                        return <FileManager/>
                        break;
                    case COMPONENTS.tenantsManager:
                        return <TenantManager/>
                        break;
                
                    default:
                        break;
                }
    }
    

    return <>
         <SidebarTenant navs={sidebarData()} />
            {
                showContent()
            }
               
            
    </>
}

export default TenantHome;