import React, { useState } from 'react';
import SidebarSetting from '../SidebarSetting';
import styled from 'styled-components';
import Button from '../buttons/Buttons';
import { useTheme, Theme, useAppContext } from '../../context/AppContext';
import './style.scss';
import {CSSTransition } from 'react-transition-group';

export default function SidebarTenant(props: any) {

    const [sidebarSettings, setSidebarSettings] = useState(false);
    const { theme, setTheme } = useTheme();
    const { user, setUser } = useAppContext();
    const [sidebarVisible, setSidebarVisible] = React.useState(true);

    const activeNavStyle = {backgroundColor: `rgba(${theme.rgb},0.08)`, boxShadow: `inset 9px 0px 0px -3px ${theme.hex}`}
    
    const CollapseButton = styled(Button)`
        position: relative;
        display: block;
        top: 132px;
        left: 85%;
        margin-top: -32px;
        z-index: 100;
        border: 1px solid ${theme.hex};
        background-color: #fff;
    `;

    return <>
    <CSSTransition in={sidebarVisible} timeout={300} classNames="sidebar">
    <div className="wrapper">
        <div className="fixed-wrapper">
            <div className="fixed">
                <div id="sidebar">
                    <CollapseButton onClick={() => setSidebarVisible(!sidebarVisible)} type="circle" size="small" icon={
                    <svg width="8px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path fill={theme.hex}
                        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                        ></path>
                </svg>} />
                    <div className="sidebar-heading">
                        <img width="48px" src="/static/icons/morgan.png" alt="avatar"/>
                        <div className="user-info d-inline-block">
                            <div className="user">{user.name.toUpperCase()}</div>
                            <div className="role text-muted">NDIS Manager</div>
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => setSidebarSettings(!sidebarSettings)} className="sidebar-toggle">
                            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g rotate="rotate(180deg)" filter="url(#filter0_d)">
                                    <circle r="15" transform="matrix(-1 0 0 1 27.1428 21)" fill={theme.hex} />
                                </g>
                                <path d="M30.6895 20.2661L27.2653 23.6904L23.841 20.2661" stroke="white" strokeWidth="1.71212" strokeLinecap="round" strokeLinejoin="round" />
                                <defs>
                                    <filter id="filter0_d" x="0.727273" y="0.576875" width="52.8312" height="52.8312" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                                        <feOffset dy="5.99242" />
                                        <feGaussianBlur stdDeviation="5.13636" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                                    </filter>
                                </defs>
                            </svg>

                        </div>
                        
                    </div>
                    {sidebarSettings ? <SidebarSetting /> : null}
                    <div className="list-group list-group-flush">
                        {props.navs && props.navs.map((i:any) => 
                            <a style={i.active ? activeNavStyle : {}} href="#" onClick={i.onClick} className="list-group-item list-group-item-action">
                                {i.icon}
                                <span className="nav-name">{i.name}</span>
                            </a>
                        )}
                        {/* <a href="#" className="list-group-item list-group-item-action" style={activeNavStyle}>
                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.4605 0.407986C10.1896 0.197338 9.81038 0.197338 9.53954 0.407986L0.539545 7.40799C0.356855 7.55008 0.25 7.76856 0.25 8V19C0.25 19.7293 0.539731 20.4288 1.05546 20.9445C1.57118 21.4603 2.27065 21.75 3 21.75H7H13H17C17.7293 21.75 18.4288 21.4603 18.9445 20.9445C19.4603 20.4288 19.75 19.7293 19.75 19V8C19.75 7.76856 19.6431 7.55008 19.4605 7.40799L10.4605 0.407986ZM13.75 20.25H17C17.3315 20.25 17.6495 20.1183 17.8839 19.8839C18.1183 19.6495 18.25 19.3315 18.25 19V8.36681L10 1.95015L1.75 8.36681V19C1.75 19.3315 1.8817 19.6495 2.11612 19.8839C2.35054 20.1183 2.66848 20.25 3 20.25H6.25V11C6.25 10.5858 6.58579 10.25 7 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11V20.25ZM7.75 20.25V11.75H12.25V20.25H7.75Z" fill={theme.hex} />
                            </svg>
                            <span className="nav-name">House List</span>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action">
                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.19439 5.44444C6.19439 3.40406 7.84845 1.75 9.88883 1.75C11.9292 1.75 13.5833 3.40406 13.5833 5.44444C13.5833 7.48483 11.9292 9.13889 9.88883 9.13889C7.84845 9.13889 6.19439 7.48483 6.19439 5.44444ZM9.88883 0.25C7.02002 0.25 4.69439 2.57563 4.69439 5.44444C4.69439 8.31326 7.02002 10.6389 9.88883 10.6389C12.7576 10.6389 15.0833 8.31326 15.0833 5.44444C15.0833 2.57563 12.7576 0.25 9.88883 0.25ZM5.44444 13.5834C4.06679 13.5834 2.74556 14.1307 1.77142 15.1048C0.79727 16.079 0.25 17.4002 0.25 18.7779V21.0001C0.25 21.4143 0.585786 21.7501 1 21.7501C1.41421 21.7501 1.75 21.4143 1.75 21.0001V18.7779C1.75 17.798 2.13923 16.8583 2.83208 16.1655C3.52492 15.4727 4.46462 15.0834 5.44444 15.0834H14.3333C15.3132 15.0834 16.2529 15.4727 16.9457 16.1655C17.6385 16.8583 18.0278 17.798 18.0278 18.7779V21.0001C18.0278 21.4143 18.3636 21.7501 18.7778 21.7501C19.192 21.7501 19.5278 21.4143 19.5278 21.0001V18.7779C19.5278 17.4002 18.9805 16.079 18.0064 15.1048C17.0322 14.1307 15.711 13.5834 14.3333 13.5834H5.44444Z" fill={theme.hex} />
                            </svg>

                            <span className="nav-name">Wait List</span>
                        </a>
                        <a href="#" className="list-group-item list-group-item-action">
                            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3 1.75C2.66848 1.75 2.35054 1.8817 2.11612 2.11612C1.8817 2.35054 1.75 2.66848 1.75 3V17C1.75 17.3315 1.8817 17.6495 2.11612 17.8839C2.35054 18.1183 2.66848 18.25 3 18.25H19C19.3315 18.25 19.6495 18.1183 19.8839 17.8839C20.1183 17.6495 20.25 17.3315 20.25 17V6C20.25 5.66848 20.1183 5.35054 19.8839 5.11612C19.6495 4.8817 19.3315 4.75 19 4.75H10C9.74924 4.75 9.51506 4.62467 9.37596 4.41603L7.59861 1.75H3ZM1.05546 1.05546C1.57118 0.539731 2.27065 0.25 3 0.25H8C8.25076 0.25 8.48494 0.375326 8.62404 0.583975L10.4014 3.25H19C19.7293 3.25 20.4288 3.53973 20.9445 4.05546C21.4603 4.57118 21.75 5.27065 21.75 6V17C21.75 17.7293 21.4603 18.4288 20.9445 18.9445C20.4288 19.4603 19.7293 19.75 19 19.75H3C2.27065 19.75 1.57118 19.4603 1.05546 18.9445C0.539731 18.4288 0.25 17.7293 0.25 17V3C0.25 2.27065 0.539731 1.57118 1.05546 1.05546Z" fill={theme.hex} />
                            </svg>

                            <span className="nav-name">File Managment</span>
                        </a> */}
                    </div>
                    <div className="nav-footer">
                        <img src="logo.svg" alt="logo" />
                        <span>By Property <br />Direct</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
        </CSSTransition>
    </>
}
