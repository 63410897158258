import React, { useState } from 'react';
import styled from 'styled-components';
import InfoCard from './components/InfoCard';
import Specification from './components/Specification';
import ImageViewer from '../../components/imageViewer/index';

function HouseInfo(props:any){

    const [imageViewerVisible, setImageViewerVisible] = useState(false);
    const SpecificationsContainer = styled.div`
        margin: 52px 0;
    `;

    const Line = styled.hr`
        background: #E4E4E4;
        margin-top: 57px;
        margin-bottom: 37px;
    `;

    const DetailTitle = styled.div`
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #B2B3BD;
        max-width: 277px;
        display: inline-block;
        margin-bottom:31px;
    `;

    const List = styled.ul`
        padding-left: 17px;
    `;

    const Container = styled.div`
        margin-left:29px;
    `;

    return <>
        <div className="row d-block-md g-0">
            <div className="col-md-4 col-sm-12 col-xs-12">
                <InfoCard 
                    image="/house.png" 
                    imgButtonText="See House Features" 
                    title={props.data.house_name}
                    detailTitle="Plan Info"
                    details="Platinum HS"
                    imageButtonClick={()=> setImageViewerVisible(!imageViewerVisible)}
                />
            </div>
            <div className="col-md-6">
                <Container>
                    <SpecificationsContainer>
                        <div className="row g-0">
                            <div className="col-md-4">
                                <Specification title="House & Land Area(m2)" value="H: 900 L: 800" />
                            </div>
                            <div className="col-md-4">
                                <Specification title="Total Package" value="$1,20,900" />
                            </div>
                            <div className="col-md-4">
                                <Specification title="Gross Yield%" value="12%" />
                            </div>
                        </div>
                    </SpecificationsContainer>
                    <Line/>
                    <DetailTitle>House cum Plan Features</DetailTitle>
                    <List>
                        <li>6 Beds 6 Bathroom 4 Cars</li>
                        <li>Close to Communtiy Center</li>
                        <li>600m from care facility</li>
                    </List>
                </Container>
            </div>
        </div>
        {imageViewerVisible && 
         <ImageViewer onClose={() => setImageViewerVisible(!imageViewerVisible)} />
       }
    </>
}

export {InfoCard, HouseInfo};