import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme, Theme } from '../../context/AppContext';

const Tag = styled.div<any>`
        background: ${props => props.bg};
        border-radius: 50px;
        color: #fff;
        display:inline-block;
    `;

const IconContainer = styled.span`
    background: #FFFFFF;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.04);
    border-radius: 50%;
    width: 20px;
    display: inline-block;
    margin: 3px 1px 0px 3px;
    height: 20px;
    text-align: center;
`;

const TagText = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 144%;
    vertical-align: text-top;
    margin-left: 7px;
    margin-right: 9px;
`;

const Icon = styled.span`
    svg {
        vertical-align: super;
        position: relative;
        left: 0px;
        top: 2px;    
    }
`;

const InfoTag = (props:any) => {
    const { theme, setTheme } = useTheme();
    return (
    <Tag bg={theme.hex}>
        <IconContainer >
            <Icon>
                {props.icon}
            </Icon>
        </IconContainer>
        <TagText>{props.text}</TagText>
    </Tag>
    );
}

export default InfoTag;